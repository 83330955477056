import { useMutation } from "@tanstack/react-query";
import { CREATE_REQUEST_ACTIONS, useCreateRequest, useCreateRequestDispatch } from "../providers/createRequestProvider";
import "./confirmCreateRequest.scss";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { TRANSACTION_DETAILS_ACTIONS, useTransactionDetails, useTransactionDetailsDispatch } from "../../providers/transactionDetailsProvider";
import { Loader2 } from "lucide-react";
import { formatAmount } from "../../../../common/formatAmount";
import Big from "big.js";
import moment from "moment";
import { PAYMENT_METHOD_TYPES } from "../../../transact/paymentmethods/configurepaymentmethods/configurepaymentmethods";
import _ from "lodash";
const ConfirmCreateRequest = () => {
	const { authTokens } = useAuth();
	// const transactDispatch = useTransactDispatch();
	const params = useParams();
	const createRequestDispatch = useCreateRequestDispatch();
	const { request } = useCreateRequest();
	const { order } = useTransactionDetails();
	const navigate = useNavigate();

	const goToConfigurePaymentMethods = () => {
		createRequestDispatch({
			type: CREATE_REQUEST_ACTIONS.goToConfigurePaymentMethods,
		});
	};

	const createRequest = useMutation({
		mutationFn: ({ request, orderId, token }) => api.createRequest({ request, orderId, token }),
		onError: () => {},
		onSuccess: (data, variables, context) => {
			console.log({ data, variables, context });
			const orderRequestId = data.data.data.attributes.id;
			createRequestDispatch({
				type: CREATE_REQUEST_ACTIONS.goToCreateRequestConfirmed,
			});
			navigate(`/transactiondetails/request/${orderRequestId}`);
		},
	});
	const formatAmounts = (amount) => {
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		let formatedAmount = parseFloat(amount);
		formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
		return formatedAmount;
	};

	const onCreateRequest = () => {
		const orderId = order.id;
		createRequest.mutate({
			request,
			orderId,
			token: authTokens.IdToken,
		});
	};

	const getYouPay = () => {
		const currency = order._rawTxn?.buyCurrency;
		const amount = formatAmount(request.desiredPurchaseAmount);
		const youPay = `${amount} ${currency}`;
		return youPay;
	};

	const getYouReceive = () => {
		const currency = order._rawTxn?.sellCurrency;
		const rate = order._rawTxn?.volumeTypeAmount;
		let amount;
		if (!_.isEmpty(`${request.desiredPurchaseAmount}`) && !_.isEmpty(rate)) {
			amount = formatAmount(Big(request.desiredPurchaseAmount).div(Big(rate)).toString());
		}
		const youReceive = `${amount} ${currency}`;
		return youReceive;
	};

	const getPaymentWindow = () => {
		const paymentWindow = `${request.windowPaymentAmount} ${request.windowPaymentType.toLowerCase()}`;
		return paymentWindow;
	};

	const getExpiry = () => {
		const expiry = moment(request.expiry).format("MMM D, YYYY [at] h:mm A");
		return expiry;
	};

	const getPaymentMethodName = (paymentMethod) => {
		let name = "...";
		switch (paymentMethod.type) {
			case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
				name = `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`;
				break;
			case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
				name = `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`;
				break;
			case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
				name = `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`;
				break;
		}
		return name;
	};


	const goBack = () => {
		// transactDispatch({ type: "clearState"});
		// transactDispatch({ type: "modelClose", modelOption: 'close'  });
	}

	return (
		<div id="createrequest_component">
			<div className="createrequest_controls">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="createrequest_controls_back"
					onClick={goToConfigurePaymentMethods}
					width="20"
					height="20"
					viewBox="0 0 24 24"
					fill="none"
					onclick={(e) => goBack(e)}>
					<path d="M16 4L8 12L16 20" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="createrequest_header">
				<p>CREATE REQUEST</p>
			</div>
			<div className="createrequest_summary">
				<div className="confirm_summary">
					<div className="confirm_header">Confirmation Page</div>

					<div className="confirm_info transaction_details">
						<div className="header">Transaction Details</div>
						<div className="section currency_pair">
							<div className="lhs">Currency pair</div>
							<div className="rhs">{order.pair}</div>
						</div>
						<div className="section price">
							<div className="lhs">Price</div>
							<div className="rhs">
								<div className="sell_price">{order.sellPrice}</div>
								<div className="buy_price">{order.buyPrice}</div>
							</div>
						</div>
						<div className="section you_pay">
							<div className="lhs">You pay</div>
							<div className="rhs">{getYouPay()}</div>
						</div>
						<div className="section you_receive">
							<div className="lhs">You recieve</div>
							<div className="rhs">{getYouReceive()}</div>
						</div>
						<hr />
					</div>

					<div className="confirm_info transaction_terms">
						<div className="header">Transaction terms</div>
						<div className="section expiry">
							<div className="lhs">Expiry Date</div>
							<div className="rhs">{getExpiry()}</div>
						</div>
						<div className="section payment_window">
							<div className="lhs">Payment window</div>
							<div className="rhs">{getPaymentWindow()}</div>
						</div>
						<hr />
					</div>

					<div className="confirm_info payment_methods">
						<div className="header">Payment method</div>

						{!_.isEmpty(request.depositAmounts) && (
							<>
								{request.depositAmounts.map((deposit) => (
									<div className="section payment_method" key={deposit.paymentMethod.id}>
										<div className="lhs">{getPaymentMethodName(deposit.paymentMethod.value)}</div>
										<div className="rhs">{deposit.instruction}</div>
									</div>
								))}
							</>
						)}

						{_.isEmpty(request.depositAmounts) && (
							<>
								{request.paymentMethods.map((paymentMethod) => (
									<div className="section payment_method" key={paymentMethod.id}>
										<div className="lhs">{getPaymentMethodName(paymentMethod)}</div>
										<div className="rhs"></div>
									</div>
								))}
							</>
						)}

						<hr />
					</div>

					<div className="confirm_info order_id">
						<div className="section order_id">
							<div className="lhs">Order ID</div>
							<div className="rhs">{order.id}</div>
						</div>
					</div>
				</div>
				<div className="summary">
					{!createRequest.isPending && (
						<div data-testid="otcrequestform_submitrequest" onClick={onCreateRequest} className="create_request">
							Submit
						</div>
					)}

					{createRequest.isPending && (
						<div className="create_request">
							<Loader2 className="create_request_loader" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default ConfirmCreateRequest;
