import React, { useState, useEffect } from "react";
import { useRates } from "../../providers/Rates/RatesProvider";
import { formatAmount, fixAmountDecimals } from "../../common/formatAmount";


const WalletBalance = ({wallets}) => {	
	const { rates, getRate } = useRates();
	const [accountTotalBalance, setAccountTotalBalance] = useState(0); 
	useEffect(() => {	
		let TotalAmount = 0;
		wallets.forEach((wallet) => {
					try{
						let rate = getRate({
							rates,
							from: wallet.currency,
							to: 'USD',
						});
						rate = fixAmountDecimals(rate, true);
						TotalAmount = parseFloat(TotalAmount) +( parseFloat(wallet.balance) * parseFloat(rate));
					} catch(e){   
						console.log(e.message);
					}
		}) 
	    setAccountTotalBalance(`$ ${formatAmount(fixAmountDecimals(TotalAmount, true))}`);
    }, [wallets]);

	return (
	  <div className="text-left column_50_ actual_balance balance_container">   
		<h3 className="text-left balance_amount">{accountTotalBalance}</h3>
		<p className="text-left balanace_title"> Total balance</p>
	  </div> 
	);
};
export default WalletBalance;
