import React, { useState, useEffect, useRef } from "react";
import { Loader2, Info } from "lucide-react";
import "../model.scss";
import Select from 'react-select';
import { useRates } from "../../../providers/Rates/RatesProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { TRANSACTION_DETAILS_ACTIONS, useTransactionDetails, useTransactionDetailsDispatch } from "../../../workspace/transactionDetails/providers/transactionDetailsProvider";
import CreateRequest from "../../../workspace/transactionDetails/createRequest/createRequest";
import { REQUESTS_ACTIONS, useRequests, useRequestsDispatch } from "../../../providers/RequestsProvider";
import { PAYMENT_METHODS_SOURCES, usePaymentMethodsDispatch } from "../../../providers/PaymentMethodsProvider";
import cn from "classnames";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { reactSelectStyle } from "../../../reactselectstyles/reactselectstyles";
import { CREATE_REQUEST_ACTIONS, useCreateRequest, useCreateRequestDispatch } from "../../../workspace/transactionDetails/createRequest/providers/createRequestProvider";
import moment from "moment";

const OrderDetails = ({modelContent, sideModel, openModelFunc}) => {
    
    const { authTokens } = useAuth();
    const createReqeustPopoverRef = useRef(null);
    const { order, back, isCancellingOrder } = useTransactionDetails();
    const { allRequests, isFetchingReceivedRequests, isFetchingAllRequests, getAllRequests, getReceivedRequests } = useRequests();
    const transactionDetailsDispatch = useTransactionDetailsDispatch();
    const createRequestDispatch = useCreateRequestDispatch();
    const [desiredPurchaseAmountError, setDesiredPurchaseAmountError] = useState("");
    const [buyCurrency, setBuyCurrency] = useState("");
    const { request } = useCreateRequest();
    const paymentMethodsDispatch = usePaymentMethodsDispatch();

    const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm();

    const REQUEST_FORM_FIELDS = {
        desiredPurchaseAmount: "desiredPurchaseAmount",
    };

    const [requestState, setRequestState] = useState({
		paymentWindowOptions: [
			{
				value: "MINUTES",
				label: "Minutes",
			},
			{
				value: "HOURS",
				label: "Hours",
			},
			{
				value: "DAYS",
				label: "Days",
			},
		],
	});

    const [dataPlaceState, setDataPlaceState] = useState({
                                                              address:""
                                                          });
  
      const defaultCurrency = (modelContent?.data?.currency !== undefined)? modelContent?.data?.currency : "UGX";
      // toggle payment types tabs 
      const handleToggleTabs = async (options) => {
        if(options?.payment_type !== ""){
          setSelectedTab(options?.payment_type)
        }
      } 
  
      const handleToggleAccountDetails = async (options) => {
          if(options?.account_id !== ""){
              if(selectedAccountDetails === options?.account_id){
                  setSelectedAccountDetails("")
              }else{setSelectedAccountDetails(options?.account_id)}
          }
      } 
  
      const handleToggleAccountMoreDetails = async (options) => {
          if(options?.account_id !== ""){
  
              if(selectedAccountMoreDetails === options?.account_id){
                  setSelectedAccountMoreDetails("")
              }else{setSelectedAccountMoreDetails(options?.account_id)}
          }
      } 
  
      const changeDefaultCountry = async (options) => {
  
          setSelectedCountry(options);
          const countryOption = 'country';
          setDataPlaceState((prevState) => ({
                                               ...prevState,
                                               countryOption: options.value,
                                           })); 
      }
  
      const changeDefaultCurrency = async (options) => {
          setSelectedCurrency({value: options.value, label: options.label});
          const currencyOption = 'currency';
          setDataPlaceState((prevState) => ({
                                               ...prevState,
                                               currencyOption: options.value,
                                           })); 																 
      }
  
      const init = async () => {
              
      }	

      useEffect(() => {
          init();
      }, []);

      const onInputChange = (field, e) => {
          setDataPlaceState((prevState) => ({
            ...prevState,
            [field]: e.target.value,
          }));
      };
  
      const handleModelClose = (e) => {
          if(modelContent?.operation_method_type_step_2 !== undefined){
              openModelFunc({operation_type: modelContent?.operation_type_step_2, operation_method_type:modelContent?.operation_method_type_step_2});
          } else {
              openModelFunc();
          }
      };

      const setMaxPurchaseAmount = () => {
		setValue(REQUEST_FORM_FIELDS.desiredPurchaseAmount, order.balanceAmount);
	  };

      
      const paymentWindowTypeChange = (evt) => {
            const newRequest = { ...request };
            newRequest.windowPaymentType = evt.value;
            createRequestDispatch({
                type: CREATE_REQUEST_ACTIONS.updateState,
                key: "request",
                value: newRequest,
            });
      };

      const startCreateRequest = (requestForm) => {
            if (requestForm.desiredPurchaseAmount > order.balanceAmount) {
                setDesiredPurchaseAmountError("Desired purchase amount exceeds the order Amount.");
                return; // Prevent proceeding with the request creation
            }
      
            // Clear error if validation passes
            setDesiredPurchaseAmountError("");
            switch (true) {
                case back.includes("marketplace"):
                    const newRequest = { ...request };
                    newRequest.desiredPurchaseAmount = requestForm.desiredPurchaseAmount;
                    newRequest.windowPaymentAmount = requestForm.paymentWindowAmount;
                    newRequest.expiry = moment().add(newRequest.windowPaymentAmount, newRequest.windowPaymentType).toISOString();
                    paymentMethodsDispatch({
                        type: "setSource",
                        source: PAYMENT_METHODS_SOURCES.MARKETPLACE,
                    });
                    createRequestDispatch({
                        type: CREATE_REQUEST_ACTIONS.updateState,
                        key: "request",
                        value: newRequest,
                    });
                    createRequestDispatch({
                        type: CREATE_REQUEST_ACTIONS.updateState,
                        key: "order",
                        value: order,
                    });
                    createRequestDispatch({
                        type: CREATE_REQUEST_ACTIONS.goToConfigurePaymentMethods,
                    });
                    createRequestDispatch({
                        type: CREATE_REQUEST_ACTIONS.setCreateReqeustPopoverRef,
                        createReqeustPopoverRef,
                    });
                    break;
            }

            //createReqeustPopoverRef.current.showPopover();
            openModelFunc({trade_request_operation: true});
      };

      const getSellBuyCurrency = () => {
		if(order.pair === undefined){
		   return;
		}
		const  currencyString = order.pair; 
		const  currencyPairs = currencyString.split(" - ");
		setBuyCurrency(currencyPairs[0]);
		return currencyPairs[0];
	  };

      //Extract order
      useEffect(() => {
        getSellBuyCurrency();
      }, []);
      
      return (
          <div className="accountsettings_paymentmethods_model">
            <div>

               <div className="accountsettings_paymentmethods_model_heading padding_bottom_10">Request</div>
               <div className="input_block form_input_field pop_model_section">
			    
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Currency Pair </strong> <span>{order.pair}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Sell Volume </strong> <span>{order.balance}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Price </strong> <span>{order.price}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Total buy volume </strong> <span>{order.totalVolume}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Status </strong> <span className={cn("status", `${order.status}`.toLowerCase())}> {order.status}</span>
				</p>
                <>
					<div className="request_container">
						<div className="request">
							<div className="purchase">
								<div className="title text-left">Purchase amount <b className="float-right-x">({buyCurrency})</b></div>
								<input  type="number"
								    placeholder="Specify the desired purchase amount"
									className={cn({
											error: errors.desiredPurchaseAmount?.type === "required",
										})}
									{...register("desiredPurchaseAmount", {
										required: "Please fill in your desired purchase amount",
										validate: { positive: (value) => parseFloat(value) > 0 || "Desired purchase amount must be greater than 0",
													notExceedBalance: (value) =>
													parseFloat(value) <= order.balanceAmount || `Desired purchase amount must not exceed the order balance (${order.balance})`, },
												})} />
										{errors.desiredPurchaseAmount && (
										    <span className="desired_purchase_amount_input_error">
											<Info className="desired_purchase_amount_input_error_icon" />{errors.desiredPurchaseAmount.message}</span>
										)}
										{desiredPurchaseAmountError && (
										    <span className="desired_purchase_amount_input_error">
											<Info className="desired_purchase_amount_input_error_icon" />
											    {desiredPurchaseAmountError}
											</span> )}
										<div className="subtitle">
											<div className="available_amount"> Available amount: <b>{order.balance}</b> </div>
											<div className="max_control" data-testid="otcrequestform_maxamount"  onClick={setMaxPurchaseAmount}>
												MAX
											</div>
										</div>
								</div>
								<div className="payment">
								<div className="title text-left">Payment window</div>
                                <div  data-testid="otcrequestform_paymentwindow"> <ReactSelect styles={reactSelectStyle} className="text-left" options={requestState.paymentWindowOptions} onChange={paymentWindowTypeChange} /></div>
								
								<input
                                     data-testid="otcrequestform_paymentwindowamount"
									type="number"
									className={cn({
												error: errors.paymentWindowAmount?.type === "required",
												})}
									{...register("paymentWindowAmount", {
										required: true,
									})}
								/>
								{errors.paymentWindowAmount?.type === "required" && (
									<span className="payment_window_amount_input_error">
										<Info className="payment_window_amount_input_error_icon" />
										Please fill in the payment window
									</span>
								)}
							</div>
							<div className="btns">
								<div className="request_btn btn" data-testid="otcrequestform_createrequest" onClick={handleSubmit(startCreateRequest)}>
									Request
								</div>
								<div className="close_btn btn" onClick={() => handleModelClose()}>
									Close
								</div>
							</div>
						</div>
					</div>
				    <div className="bottom_spacer"></div>
		        </>


                
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>User </strong> <span>{order.username}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Date </strong> <span>{order.date}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Expiry date </strong> <span>{order.expiry}</span>
				</p>
                <p className="flex_container flexing_content space_between bank_details">
				  <strong>Order ID </strong> <span>{order.id}</span>
				</p>

               </div> 
		    </div>
          </div>
      );
};
export default OrderDetails;
