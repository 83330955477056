import React, { useState, useEffect } from "react";
import "./settings.scss";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import Select from 'react-select';
import bellIcon from "../../assets/notification_icon.svg";
import editIcon from "../../assets/edit_icon.svg";
import profileImage from "../../assets/profile-pic.png";
import VerifyIdentity from "./identityVerification/identityVerification";
import { useNavigate } from "react-router-dom";
import MyCompany from "./myCompany/myCompany";
import Paymentmethods from "./paymentmethods/paymentmethods";
import AccountDetails from "./accountDetails/accountDetails";
import PasswordSecurity from "./passwordandsecurity/passwordandsecurity";
const Settings = () => {
	const navigate = useNavigate();
	const [selectedMenuItem, setSelectedMenuItem] = useState("My Account");//Payment methods
	const [marketplaceState, setMarketplaceState] = useState({
				currencyOptions: [{label: 'USD', value: 'USD'}],
				buyCurrencyFilter: [],
				sellCurrencyFilter: []
	        });
			
	const handleMenuItemClick = (menuItem) => {
		const checkPasswordRest = onCheckAuthPasswordReset();
		if(checkPasswordRest === 'true'){
		   setSelectedMenuItem("Password and security");
		} else {
		   setSelectedMenuItem(menuItem);
		}   
	};


	const goToCreateCompanyPage = () => {
		navigate("/create_company");
	};
	const onCheckAuthPasswordReset = () => {
		return Cookies.get("authResetPassKey");
	};

	useEffect(() => {
	   handleMenuItemClick("My Account"); // Payment methods 
	}, [])

	const renderSettingsWidgetContent = () => {
		switch (selectedMenuItem) {
			case "My Account":
					return <AccountDetails />;
					// <>
					// 	<div className="general">
					// 		<div className="tab_header">
					// 			<span className="tab_header_text">General</span>
					// 			<img src={editIcon} alt="" />
					// 		</div>

					// 		<div className="data_display">
					// 			<div className="data_group">
					// 				<div className="inner_data_group">
					// 					<div className="profile-picture">
					// 						<img src={profileImage} alt="profile" />
					// 					</div>
					// 					<span className="change_photo">Change photo</span>
					// 				</div>
					// 			</div>
					// 			<div className="data_group">
					// 				<div className="inner_data_group">
					// 					<h4>Username</h4>
					// 					<p>Sarah M.</p>
					// 				</div>
					// 				<div className="inner_data_group">
					// 					<h4>Email</h4>
					// 					<p>sarah.melbourne@gmail.com</p>
					// 				</div>
					// 			</div>
					// 			<div className="data_group">
					// 				<div className="inner_data_group">
					// 					<h4>Name</h4>
					// 					<p>Sarah Melbourne</p>
					// 				</div>
					// 				<div className="inner_data_group">
					// 					<h4>Phone</h4>
					// 					<p>+441234567890</p>
					// 				</div>
					// 			</div>
					// 			<div className="data_group">
					// 				<div className="inner_data_group">
					// 					<h4>Gender</h4>
					// 					<p>Female</p>
					// 				</div>
					// 				<div className="inner_data_group">
					// 					<h4>Date of birth</h4>
					// 					<p>11/11/1987</p>
					// 				</div>
					// 			</div>
					// 		</div>
					// 	</div>
					// 	<div className="location">
					// 		<div className="location_container">
					// 			<div className="tab_header">
					// 				<span className="tab_header_text">Location</span>
					// 				<img src={editIcon} alt="" />
					// 			</div>
					// 			<div className="data_display">
					// 				<div className="inner_data_group">
					// 					<h4>Address</h4>
					// 					<p>Canada water str. 194/1</p>
					// 					<p>London, SE16 7ET</p>
					// 					<p>United Kingdom</p>
					// 				</div>
					// 				<div className="inner_data_group">
					// 					<h4>Time zone</h4>
					// 					<p>UTC+02:00 Berlin, Stockholm, Rome, Bern, Brussels</p>
					// 				</div>
					// 			</div>
					// 		</div>
					// 		<div className="additional_accounts">
					// 			<div className="tab_header">
					// 				<span className="tab_header_text">Additional accounts</span>
					// 			</div>
					// 			<div className="data_display">
					// 				<div className="data_group">
					// 					<p>
					// 						Creating company account allows you to use Muda in different ways, while still
					// 						<br />
					// 						having just one login. Manage teams and company transfers from one common <br /> company account.
					// 					</p>
					// 				</div>
					// 				<div className="create_acct_btn_box">
					// 					<div className="create_acct_btn" onClick={goToCreateCompanyPage}>
					// 						Create company account
					// 					</div>
					// 				</div>
					// 			</div>
					// 		</div>
					// 	</div>

					// 	<div className="widget_footer">
					// 		<span className="widget_footer_text">Close my account</span>
					// 	</div>
					// </>
				// );
			case "My teams":
				return <MyCompany />;
			case "Identity verification":
				return <VerifyIdentity onMyAccountClick={() => handleMenuItemClick("My Account")} />;
			
			case "Payment methods":
				return <Paymentmethods></Paymentmethods>;

			case "Password and security":
				return <PasswordSecurity></PasswordSecurity>;
			// Add cases for other menu items as needed
			default:
				return null;
		}
	};

	const reactSelectStyleMarketplace = {
		control: (baseStyles, state) => ({
			...baseStyles,
			fontSize: ".9rem",
			fontWeight: "600",
			padding: ".2rem",
			borderRadius: "8px",
			width: "9rem",
			color: "#172c50",
			backgroundColor: "#ffffff",
			borderColor: "#3E9CF3",
		}),
	};

	const changeDefaultCurreny = () => {

	}

	return (
		<div className="settings_page scroll_right flexing_content flex_container">
			
			<div className="section_main_header">
			   <div className="left_section text_left">
				  <span>Settings</span>
				</div>

				<div className="right_section text_right hidden">
				  <div className="default_currency_title">Default currency</div>
				  <Select
                           value={""}
						   className="input_select"
                           onChange={changeDefaultCurreny}
                           options={marketplaceState.currencyOptions}
						   isSearchable="true" />				
				</div>
			</div>			
            <div className="settings_body flexing_content flex_container">
				<div className="settings_menu column_30">
					<ul className="settings_list">
						<li className={`menu_item ${selectedMenuItem === "My Account" ? "active" : ""}`} onClick={() => handleMenuItemClick("My Account")}>
							<a href="#my-account">My Account</a>
						</li>

						<li className={`hidden menu_item ${selectedMenuItem === "My teams" ? "active" : ""}`} onClick={() => handleMenuItemClick("My teams")}>
							<a href="#my-teams">My teams</a>
						</li>
						<li
							className={`hidden menu_item ${selectedMenuItem === "Identity verification" ? "active" : ""}`}
							onClick={() => handleMenuItemClick("Identity verification")}>
							<a href="#identity-verification">Identity verification</a>
						</li>
						<li className={`hidden menu_item ${selectedMenuItem === "Trading settings" ? "active" : ""}`} onClick={() => handleMenuItemClick("Trading settings")}>
							<a href="#trading-settings">Trading settings</a>
						</li>
						<li className={`menu_item ${selectedMenuItem === "Payment methods" ? "active" : ""}`} onClick={() => handleMenuItemClick("Payment methods")}>
							<a href="#payment-methods">Payment methods</a>
						</li>
						<li
							className={`menu_item ${selectedMenuItem === "Password and security" ? "active" : ""}`}
							onClick={() => handleMenuItemClick("Password and security")}>
							<a href="#password-and-security">Password and security</a>
						</li>
					</ul>
				</div>

				<div className="settings_widget column_70 ">{renderSettingsWidgetContent()}</div>
			</div>

		</div>
	);
};
export default Settings;
