import { ChevronLeft, Info, Loader2, RefreshCw } from "lucide-react";
import "./transactionDetails.scss";
import { TRANSACTION_DETAILS_ACTIONS, useTransactionDetails, useTransactionDetailsDispatch } from "./providers/transactionDetailsProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { reactSelectStyle } from "../../reactselectstyles/reactselectstyles";
import ReactSelect from "react-select";
import { useProfile } from "../../providers/ProfileProvider";
import { useEffect, useRef, useState } from "react";
import CreateRequest from "./createRequest/createRequest";
import { PAYMENT_METHODS_SOURCES, usePaymentMethodsDispatch } from "../../providers/PaymentMethodsProvider";
import AllRequests from "./allRequests/allRequests";
import OrderPops from "./orderPops/orderPops";
import { CREATE_REQUEST_ACTIONS, useCreateRequest, useCreateRequestDispatch } from "./createRequest/providers/createRequestProvider";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { useMarketplace } from "../marketplace/providers/MarketplaceProvider";
import { getProcessedOrder } from "../../common/getProcessedOrder";
import Big from "big.js";
import { useAuth } from "../../providers/AuthProvider";
import { useMutation } from "@tanstack/react-query";
import api from "../../api";
import moment from "moment";
import { REQUESTS_ACTIONS, useRequests, useRequestsDispatch } from "../../providers/RequestsProvider";

const REQUEST_FORM_FIELDS = {
	desiredPurchaseAmount: "desiredPurchaseAmount",
};
const TRANSACITON_DETAILS_TABS = {
	DETAILS: "DETAILS",
	REQUESTS: "REQUESTS",
	POPS: "POPS",
};
const TransactionDetails = () => {
	const { authTokens } = useAuth();
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm();
	const [transactionState, setTransactionState] = useState({
		activeTab: TRANSACITON_DETAILS_TABS.DETAILS,
		loadingPops: false,
		myPops: [],
		otherPops: [],
		updatingPops: [],
	});
	const { order, back, isCancellingOrder } = useTransactionDetails();
	const { allRequests, isFetchingReceivedRequests, isFetchingAllRequests, getAllRequests, getReceivedRequests } = useRequests();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const [desiredPurchaseAmountError, setDesiredPurchaseAmountError] = useState("");
	const [buyCurrency, setBuyCurrency] = useState("");


	const { profile, isFetchingProfile } = useProfile();
	const navigate = useNavigate();
	const cancelOrderDialogRef = useRef(null);
	const createReqeustPopoverRef = useRef(null);
	const paymentMethodsDispatch = usePaymentMethodsDispatch();
	const createRequestDispatch = useCreateRequestDispatch();
	const requestsDispatch = useRequestsDispatch();
	const { request } = useCreateRequest();

	const [requestState, setRequestState] = useState({
		paymentWindowOptions: [
			{
				value: "MINUTES",
				label: "Minutes",
			},
			{
				value: "HOURS",
				label: "Hours",
			},
			{
				value: "DAYS",
				label: "Days",
			},
		],
	});
	const updateState = ({ key, value }) => {
		const newState = { ...transactionState };
		newState[key] = value;
		setTransactionState(newState);
	};
	const updateStates = (states) => {
		const newState = { ...transactionState };
		states.forEach(({ key, value }) => {
			newState[key] = value;
		});
		setTransactionState(newState);
	};
	const { marketplaceOrders, isFetchingMarketplaceOrders, reloadMarketPlaceOrders } = useMarketplace();
	const params = useParams();

	const goBack = () => {
		if (!_.isEmpty(back)) {
			navigate(back);
		} else {
			navigate("/marketplace");
		}
	};

	const loadingOrders = () => {
		return isFetchingMarketplaceOrders;
	};

	const getSellBuyCurrency = () => {
		if(order.pair === undefined){
		   return;
		}
		const  currencyString = order.pair; 
		const  currencyPairs = currencyString.split(" - ");
		setBuyCurrency(currencyPairs[0]);
		return currencyPairs[0];
	};

	//Extract order
	useEffect(() => {
		if (_.isEmpty(order)) {
			if (!_.isEmpty(marketplaceOrders)) {
				const orderId = params.orderId;
				const order = _.find(marketplaceOrders, { id: orderId });
				if (!_.isEmpty(order)) {
					const processedOrder = getProcessedOrder({ order });
					transactionDetailsDispatch({
						type: "open",
						order: processedOrder,
						back: "/marketplace",
					});
				}
			}
		}
		getSellBuyCurrency();
	}, [loadingOrders, order]);

	const userIsOwnerOfOrder = () => {
		const isOwner = order.createdBy == profile.id;
		return isOwner;
	};

	const orderIsFromMarketplace = () => {
		const fromMarketplace = back.includes("marketplace");
		return fromMarketplace;
	};

	const startCancelOrder = () => {
		cancelOrderDialogRef.current.showModal();
	};

	const cancelOrder = useMutation({
		mutationFn: ({ order, token }) => {
			transactionDetailsDispatch({
				type: TRANSACTION_DETAILS_ACTIONS.updateState,
				key: "isCancellingOrder",
				value: true,
			});
			return api.cancelOrder({ order, token });
		},
		onError: (error, variables, context) => {
			console.log(error);
			transactionDetailsDispatch({
				type: TRANSACTION_DETAILS_ACTIONS.updateState,
				key: "isCancellingOrder",
				value: false,
			});
		},
		onSuccess: async () => {
			transactionDetailsDispatch({
				type: TRANSACTION_DETAILS_ACTIONS.updateState,
				key: "isCancellingOrder",
				value: false,
			});
			await reloadMarketPlaceOrders();
			if (cancelOrderDialogRef.current) {
				cancelOrderDialogRef.current.close();
			}
			navigate("/marketplace");
		},
	});

	const continueCancelOrder = () => {
		(async () => {
			const token = authTokens.IdToken;
			cancelOrder.mutate({ order, token });
		})();
	};

	const stopCancelOrder = () => {
		cancelOrderDialogRef.current.close();
	};

	const startCreateRequest = (requestForm) => {
		if (requestForm.desiredPurchaseAmount > order.balanceAmount) {
			setDesiredPurchaseAmountError("Desired purchase amount exceeds the order Amount.");
			return; // Prevent proceeding with the request creation
		}

		// Clear error if validation passes
		setDesiredPurchaseAmountError("");
		switch (true) {
			case back.includes("marketplace"):
				const newRequest = { ...request };
				newRequest.desiredPurchaseAmount = requestForm.desiredPurchaseAmount;
				newRequest.windowPaymentAmount = requestForm.paymentWindowAmount;
				newRequest.expiry = moment().add(newRequest.windowPaymentAmount, newRequest.windowPaymentType).toISOString();

				paymentMethodsDispatch({
					type: "setSource",
					source: PAYMENT_METHODS_SOURCES.MARKETPLACE,
				});
				createRequestDispatch({
					type: CREATE_REQUEST_ACTIONS.updateState,
					key: "request",
					value: newRequest,
				});
				createRequestDispatch({
					type: CREATE_REQUEST_ACTIONS.updateState,
					key: "order",
					value: order,
				});
				createRequestDispatch({
					type: CREATE_REQUEST_ACTIONS.goToConfigurePaymentMethods,
				});
				createRequestDispatch({
					type: CREATE_REQUEST_ACTIONS.setCreateReqeustPopoverRef,
					createReqeustPopoverRef,
				});
				break;
		}
		createReqeustPopoverRef.current.showPopover();
	};

	const setMaxPurchaseAmount = () => {
		setValue(REQUEST_FORM_FIELDS.desiredPurchaseAmount, order.balanceAmount);
	};

	const paymentWindowTypeChange = (evt) => {
		const newRequest = { ...request };
		newRequest.windowPaymentType = evt.value;
		createRequestDispatch({
			type: CREATE_REQUEST_ACTIONS.updateState,
			key: "request",
			value: newRequest,
		});
	};

	const canCancelOrder = () => {
		return order.status == "OPEN" && order.balanceAmount > 0;
	};

	const switchToTab = (tab) => {
		const newTransactionState = {
			...transactionState,
		};
		newTransactionState.activeTab = tab;
		setTransactionState(newTransactionState);
	};

	const canCreateRequest = () => {
		return order.status == "OPEN" && order.balanceAmount > 0;
	};

	const loadingPage = () => {
		return isFetchingAllRequests || transactionState.loadingPops || isFetchingReceivedRequests;
	};

	const userIsSeller = userIsOwnerOfOrder;
	const getPops = async (request) => {
		const domain = api.getApiDomain();
		updateState({ key: "loadingPops", value: true });

		const order = request.order;
		const result = await api.getOrderVerifiedProofOfPayments({
			token: authTokens.IdToken,
			order,
		});
		const proofOfPayments = result.data.data.map((datum) => {
			const pop = datum.attributes;
			const fileUrl = `${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/files/${pop.file}?token=${authTokens.IdToken}`;
			pop.fileUrl = fileUrl;
			return pop;
		});
		const buyerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "BUYER_POP");
		const sellerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "SELLER_POP");

		if (userIsSeller()) {
			updateStates([
				{ key: "paymentMethods", value: popStatePaymentMethods },
				{ key: "loadingPops", value: false },
				{ key: "myPops", value: sellerProofOfPayments },
				{ key: "otherPops", value: buyerProofOfPayments },
			]);
		} else {
			updateStates([
				{ key: "loadingPops", value: false },
				{ key: "myPops", value: buyerProofOfPayments },
				{ key: "otherPops", value: sellerProofOfPayments },
			]);
		}
	};

	const reloadPage = async () => {
		//Clear any previous state to prevent stale cache issues
		transactionDetailsDispatch({
			type: "updateState",
			key: "order",
			value: {},
		});

		requestsDispatch({
			type: REQUESTS_ACTIONS.updateStates,
			states: {
				isFetchingAllRequests: true,
				isFetchingReceivedRequests: true,
			},
		});

		const myRequests = _.flattenDeep(
			(await Promise.allSettled([getAllRequests.refetch(), getReceivedRequests.refetch()])).map((result) => {
				let request = {};
				if (result.status == "fulfilled") {
					request = result.value.data.data.data.map((datum) => datum.attributes);
				}
				return request;
			})
		);

		requestsDispatch({
			type: REQUESTS_ACTIONS.updateStates,
			states: {
				isFetchingAllRequests: false,
				isFetchingReceivedRequests: false,
			},
		});
		//console.log(myRequests);
		const requestId = params.requestId;
		const foundRequest = _.find(myRequests, { id: requestId });
		if (!_.isEmpty(foundRequest)) {
			transactionDetailsDispatch({
				type: "updateState",
				key: "order",
				value: foundRequest.order,
			});

			const desiredPurchaseAmountFormatted = formatAmounts(foundRequest.desiredPurchaseAmount);
			foundRequest.desiredPurchaseAmountFormatted = desiredPurchaseAmountFormatted;

			const desiredPurchaseAmountConverted = Big(foundRequest.desiredPurchaseAmount).div(Big(foundRequest.order.volumeTypeAmount)).toString();
			foundRequest.desiredPurchaseAmountConverted = desiredPurchaseAmountConverted;

			const desiredPurchaseAmountConvertedFormatted = formatAmounts(desiredPurchaseAmountConverted);
			foundRequest.desiredPurchaseAmountConvertedFormatted = desiredPurchaseAmountConvertedFormatted;
			//Get Pops
			getPops(foundRequest);

		} else {
			// Either request doesn't exist or user doesn't have permission to view the request
		}
	};

	return (
		<div id="transaction_details_page">
			<div className="back_container">
				<ChevronLeft className="back_icon" onClick={goBack} />
			</div>
			<div className="heading">
				<div className="title">Transaction details</div>
				<RefreshCw  data-testid="txndetails-refresh"onClick={reloadPage} className={cn("refresh_icon", { rotating: loadingPage() })} />
			</div>

			{(loadingOrders() || isFetchingProfile) && (
				<div className="transaction_details_orders_loader_container">
					Loading transaction...
					<Loader2 className="transaction_details_orders_loader" />
				</div>
			)}

			{!loadingOrders() && !isFetchingProfile && (
				<div className="details">
					<div className="heading">
						<div
							onClick={() => switchToTab(TRANSACITON_DETAILS_TABS.DETAILS)}
							className={cn("title", {
								active: transactionState.activeTab === TRANSACITON_DETAILS_TABS.DETAILS,
							})}>
							Details
						</div>
						{userIsOwnerOfOrder() && (
							<>
								<div
								    data-testid="otctxndetails-requests"
									onClick={() => switchToTab(TRANSACITON_DETAILS_TABS.REQUESTS)}
									className={cn("title", {
										active: transactionState.activeTab === TRANSACITON_DETAILS_TABS.REQUESTS,
									})}>
									Requests
								</div>
								<div
									onClick={() => switchToTab(TRANSACITON_DETAILS_TABS.POPS)}
									className={cn("title", {
										active: transactionState.activeTab === TRANSACITON_DETAILS_TABS.POPS,
									})}>
									POPS
								</div>
							</>
						)}
					</div>

					{transactionState.activeTab === TRANSACITON_DETAILS_TABS.DETAILS && (
						<>
							{userIsOwnerOfOrder() && (
								<>
									<div className="body" data-testid="transactiondetails-otctrade">
										<div className="section">
											<div className="lhs">Currency pair</div>
											<div className="rhs">{order.pair}</div>
										</div>
										<div className="section">
											<div className="lhs">Amount</div>
											<div className="rhs">{order.amount}</div>
										</div>
										<div className="section">
											<div className="lhs">Balance</div>
											<div className="rhs">{order.balance}</div>
										</div>
										<div className="section">
											<div className="lhs">Price</div>
											<div className="rhs">{order.price}</div>
										</div>
										<div className="section">
											<div className="lhs">Total volume ({order?.pair?.split(" - ")[1]})</div>
											<div className="rhs">{order.totalVolume}</div>
										</div>
										<div className="section">
											<div className="lhs">Status</div>
											<div className="rhs">
												<div className={cn("status", `${order.status}`.toLowerCase())}>{order.status}</div>
											</div>
										</div>
										<div className="section spacer">
											<div className="lhs"></div>
											<div className="spacer">
												<hr />
											</div>
											<div className="rhs"></div>
										</div>
										<div className="section">
											<div className="lhs">Username</div>
											<div className="rhs">{order.username}</div>
										</div>
										<div className="section">
											<div className="lhs">Date</div>
											<div className="rhs">{order.date}</div>
										</div>
										<div className="section">
											<div className="lhs">Expiry date</div>
											<div className="rhs">{order.expiry}</div>
										</div>
										<div className="section">
											<div className="lhs">Order ID</div>
											<div className="rhs">{order.id}</div>
										</div>
									</div>
									{canCancelOrder() && (
										<div className="cancel_order_container">
											<div className="cancel_order_btn" onClick={startCancelOrder}>
												Cancel order
											</div>
											<dialog className="cancel_order_dialog_container" ref={cancelOrderDialogRef}>
												<div className="cancel_order_dialog_heading">Are you sure you want to cancel this order?</div>
												{isCancellingOrder && (
													<div className="cancel_order_loader_container">
														Cancelling order ...
														<Loader2 className="cancel_order_loader" />
													</div>
												)}
												{!isCancellingOrder && (
													<div className="cancel_order_dialog_controls">
														<div onClick={continueCancelOrder} className="cancel_order_dialog_control cancel_order_dialog_confirm">
															Yes
														</div>
														<div onClick={stopCancelOrder} className="cancel_order_dialog_control cancel_order_dialog_cancel">
															No
														</div>
													</div>
												)}
											</dialog>
										</div>
									)}
								</>
							)}

							{!userIsOwnerOfOrder() && (
								<>
									<div className="body">
										<div className="section">
											<div className="lhs">Currency pair</div>
											<div className="rhs">{order.pair}</div>
										</div>
										<div className="section">
											<div className="lhs">Amount</div>
											<div className="rhs">{order.balance}</div>
										</div>
										<div className="section">
											<div className="lhs">Price</div>
											<div className="rhs">{order.price}</div>
										</div>
										<div className="section">
											<div className="lhs">Total volume ({order?.pair?.split(" - ")[1]})</div>
											<div className="rhs">{order.totalVolume}</div>
										</div>
										<div className="section">
											<div className="lhs">Status</div>
											<div className="rhs">
												<div className={cn("status", `${order.status}`.toLowerCase())}>{order.status}</div>
											</div>
										</div>
										<div className="section spacer">
											<div className="lhs"></div>
											<div className="spacer">
												<hr />
											</div>
											<div className="rhs"></div>
										</div>
										<div className="section">
											<div className="lhs">Username</div>
											<div className="rhs">{order.username}</div>
										</div>
										<div className="section">
											<div className="lhs">Date</div>
											<div className="rhs">{order.date}</div>
										</div>
										<div className="section">
											<div className="lhs">Expiry date</div>
											<div className="rhs">{order.expiry}</div>
										</div>
										<div className="section">
											<div className="lhs">Order ID</div>
											<div className="rhs">{order.id}</div>
										</div>
									</div>

									{canCreateRequest() && (
										<>
											<div className="request_container">
												<div className="request">
													<div className="purchase">
														<div className="title">Purchase amount <b className="float-right-x">({buyCurrency})</b></div>
														<input

															type="number"
															placeholder="Specify the desired purchase amount"
															className={cn({
																error: errors.desiredPurchaseAmount?.type === "required",
															})}
															{...register("desiredPurchaseAmount", {
																onChange: () => {
																	console.log(`Input changed...`)
																},
																required: "Please fill in your desired purchase amount",
																validate: {
																	positive: (value) => parseFloat(value) > 0 || "Desired purchase amount must be greater than 0",
																	notExceedBalance: (value) =>
																		parseFloat(value) <= order.balanceAmount || `Desired purchase amount must not exceed the order balance (${order.balance})`,
																},
															})}
														/>
														{errors.desiredPurchaseAmount && (
															<span className="desired_purchase_amount_input_error">
																<Info className="desired_purchase_amount_input_error_icon" />
																{errors.desiredPurchaseAmount.message}
															</span>
														)}
														{desiredPurchaseAmountError && (
															<span className="desired_purchase_amount_input_error">
																<Info className="desired_purchase_amount_input_error_icon" />
																{desiredPurchaseAmountError}
															</span>
														)}
														<div className="subtitle">
															<div className="available_amount">
															  Available amount: <b>{order.balance}</b>
															</div>
															<div className="max_control" data-testid="otcrequestform_maxamount" onClick={setMaxPurchaseAmount}>
																MAX
															</div>
														</div>
													</div>
													<div className="payment">
														<div className="title">Payment window</div>
														<div data-testid="otcrequestform_paymentwindow"><ReactSelect styles={reactSelectStyle} options={requestState.paymentWindowOptions} onChange={paymentWindowTypeChange} /></div>
														
														<input
														    data-testid="otcrequestform_paymentwindowamount"
															type="number"
															className={cn({
																error: errors.paymentWindowAmount?.type === "required",
															})}
															{...register("paymentWindowAmount", {
																required: true,
															})}
														/>
														{errors.paymentWindowAmount?.type === "required" && (
															<span className="payment_window_amount_input_error">
																<Info className="payment_window_amount_input_error_icon" />
																Please fill in the payment window
															</span>
														)}
													</div>
													<div className="btns">
														<div  data-testid="otcrequestform_createrequest"className="request_btn btn" onClick={handleSubmit(startCreateRequest)}>
															Request
														</div>
														<div className="close_btn btn" onClick={goBack}>
															Close
														</div>
													</div>
												</div>
											</div>

											
											<div id="create_request_popover" popover="auto" ref={createReqeustPopoverRef}>
												<CreateRequest />
											</div>
											<div className="bottom_spacer"></div>
										</>
									)}
								</>
							)}
						</>
					)}
				</div>
			)}
			{userIsOwnerOfOrder() && (
				<>
					{transactionState.activeTab === TRANSACITON_DETAILS_TABS.REQUESTS && <AllRequests />}
					{transactionState.activeTab === TRANSACITON_DETAILS_TABS.POPS && <OrderPops />}
				</>
			)}
		</div>
	);
};
export default TransactionDetails;
