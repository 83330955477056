import _ from "lodash";
export const formatAmount = (amount) => {
    try{
        amount = parseFloat(amount);
        const locale = Intl.NumberFormat().resolvedOptions().locale;
        const options = { minimumFractionDigits: 0, maximumFractionDigits: 8 };
        const result = `${amount.toLocaleString(locale, options)}`;
        return !_.isNaN(amount) ? result : "";
    } catch(e){
        
        return amount;
    }
};

export const fixAmountDecimals = (value, fiat = false) => {
    let num = Number(value); 
    if (isNaN(num)) return null;
    
    if(fiat){
      return Number(num.toFixed(2));
    }

    const decimalPlaces = num.toString().split(".")[1]?.length || 0;

    if (decimalPlaces > 8) {
        return Number(num.toFixed(8));
    } else if (decimalPlaces > 4) {
        return Number(num.toFixed(4));
    } else if (decimalPlaces > 2) {
        return Number(num.toFixed(2));
    }

    return num;
}