import React, {useState, useEffect} from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/header";
import { Loader2, Info } from "lucide-react";
import cn from "classnames";
import "./login.scss";
import api from "../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../providers/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
//import {constants} from '../helpers/constants'
// import speakeasy from "speakeasy";

const Login = () => {
	
	const { setIsLoggedIn, setAuthTokens, isLoggedIn } = useAuth();
	const [captchaValue, setCaptchaValue] = useState(null);
	const [accountCredentilas, setAccountCredentilas] = useState("");
	const [changePasswordCondition, setChangePasswordCondition] = useState("");
	const [isMFARequired, setIsMFARequired] = useState(false);
    const [totpCode, setTotpCode] = useState("");
    const [mfaError, setMfaError] = useState("");
	const navigate = useNavigate();

	if (isLoggedIn) {
		navigate(`/`);
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
	   const inti = () => {
		 try{
		    
			// var secretx = speakeasy.generateSecret();
		    // console.log("secret ------ ", secretx);
			//    const secret = speakeasy.generateSecret({ length: 20, name: "MyAppName" });
			//    console.log("Secret Base32 over all:", secret); // Use this for Google Authenticator
			//    console.log("Secret Base32:", secret.base32); // Use this for Google Authenticator
			//    console.log("otpauth URL:", secret.otpauth_url); // Use this for generating a QR code
		 } catch(e){
        
		 }
		  
	   }	
	   inti();
	}, []);

	const login = useMutation({
		mutationFn: (creds) => {

			Cookies.remove("authResetPassKey")
			setAccountCredentilas(creds?.password);
			const checkPasswordResetCond = creds?.password?.startsWith("ab1U?");
			setChangePasswordCondition(checkPasswordResetCond);
			return api.login(creds);
		},
		onError: (error, variables, context) => {
			const errors = error.response.data.errors;
			const emailNotConfirmed = _.find(errors, ["title", "Email not confirmed"]);
			setAccountCredentilas("");
			setChangePasswordCondition(false);
			if (!_.isEmpty(emailNotConfirmed)) {
				const email = variables.email;
				navigate(`/confirm/email?email=${encodeURIComponent(email)}`);
			}
		},
		onSuccess: (data, variables, context) => {
			
			const authTokens = data.data.data.attributes;

			if (authTokens.challenge === "SOFTWARE_TOKEN_MFA") {
				localStorage.setItem("hasMFAEnabled", true);
				// Store MFA session for the next request
				setIsMFARequired(true);
				sessionStorage.setItem("mfaSession", JSON.stringify(authTokens.session));
				sessionStorage.setItem("mfaEmail", variables.email);
				return; // Stop further processing
			}

			localStorage.setItem("hasMFAEnabled", false);
			if(changePasswordCondition === true){
			  Cookies.set("authResetPassKey", 'true', { expires: 1 });
			}  else {
			   Cookies.set("authResetPassKey", 'false', { expires: 1 });
			}

			setAccountCredentilas("");
			setChangePasswordCondition(false);
			localStorage.setItem("authTokens", JSON.stringify(authTokens));
			setAuthTokens(authTokens);
			setIsLoggedIn(true);
			window.location.href = "/";
			
		},
	});


	const onLogin = (creds) => {
		// if (!captchaValue) {
		//   return alert("Please complete the CAPTCHA");
		// }
		login.mutate(creds);
	};

	const mfaLogin = useMutation({
		mutationFn: () => {
				
		const session = JSON.parse(sessionStorage.getItem("mfaSession"));
		const email = sessionStorage.getItem("mfaEmail");

			return api.mfaLogin({
				totp: totpCode,
				session,
				email
			  });
		},
		onError: (error, variables, context) => {
			const errors = error.response.data.errors;
			setMfaError("Invalid TOTP. Please try again.");
		
		},
		onSuccess: (data, variables, context) => {
			const authTokens = data.data.data.attributes;
			if(changePasswordCondition === true){
			  Cookies.set("authResetPassKey", 'true', { expires: 1 });
			}  else {
			   Cookies.set("authResetPassKey", 'false', { expires: 1 });
			}
			localStorage.setItem("authTokens", JSON.stringify(authTokens));
			setAccountCredentilas("");
			setChangePasswordCondition(false);
			setAuthTokens(authTokens);
			setIsLoggedIn(true);
			window.location.href = "/";
		},
	});



	const handleMFA = async () => {
		if (!totpCode) {
			setMfaError("Please enter the TOTP.");
			return;
		}
	mfaLogin.mutate();

	};
	

	const handleForgotPasswordClick = () => {
		navigate("/start/reset/password");
	};

	const handleCaptchaChange = (value) => {
		setCaptchaValue(value);
	};

	return (
		<>
    <Header />
    <div className="login_page">
      {isMFARequired ? (
        <div className="login_form">
          <div className="login_header_mfa">Two-Factor Authentication</div>
          <div className="login_input_group mfa">
            <label className="login_input">
              <span className="login_input_label">Enter the code from your authenticator app </span>
              <input
                placeholder="Enter the code"
                name="totp"
                className={cn({ error: mfaError })}
                value={totpCode}
                onChange={(e) => setTotpCode(e.target.value)}
              />
              {mfaError && <span className="login_input_error">{mfaError}</span>}
            </label>
          </div>
		  <div className="spacer" />
          <div className="login_btn_box">
            <div 
			className={cn('login_btn', { is_logging_in: mfaLogin.isPending })}
			 onClick={handleMFA}>
                { mfaLogin.isPending ? <Loader2 className="login_loader" /> : <span>Log in</span>}
            </div>
          </div>
        </div>
      ) : (
        <div className="login_form">
          <div className="login_header">Log in</div>
          <div className="login_input_group email">
            <label className="login_input">
              <span className="login_input_label">
                Email <span className="required">*</span>
              </span>
              <input
                placeholder="Enter your email"
                name="email"
                data-testid="login-username"
                className={cn({
                  error: errors.email?.type === 'required' || login.isError,
                })}
                {...register('email', { required: true })}
              />
              {errors.email?.type === 'required' && (
                <span className="login_input_error">
                  <Info className="login_input_error_icon" /> Email is required
                </span>
              )}
            </label>
          </div>
          <div className="spacer" />
          <div className="login_input_group password">
            <label className="login_input">
              <span className="login_input_label">
                Password <span className="required">*</span>
              </span>
              <input
                type="password"
                placeholder="Enter your password"
                name="password"
                data-testid="login-password"
                className={cn({
                  error: errors.password?.type === 'required' || login.isError,
                })}
                {...register('password', { required: true })}
              />
              {errors.password?.type === 'required' && (
                <span className="login_input_error">
                  <Info className="login_input_error_icon" /> Password is required
                </span>
              )}
            </label>
          </div>

          <div className="login_forgot_password" onClick={handleForgotPasswordClick}>
            <span className="login_forgot_password_label">Forgot password?</span>
          </div>
          <div className="spacer" />
          <div className="login_error_box">
            {login.isError && (
              <span className="signup_input_error">
                <Info className="signup_input_error_icon" /> Please check your email or password, and try again
              </span>
            )}
          </div>

          <div className="login_btn_box">
            <div
              data-testid="login-submit"
              className={cn('login_btn', { is_logging_in: login.isPending })}
              onClick={handleSubmit(onLogin)}
            >
              {login.isPending ? <Loader2 className="login_loader" /> : <span>Log in</span>}
            </div>
          </div>
          <div className="spacer" />
          <div className="or_box">
            <hr /> <span className="or">or</span> <hr />
          </div>
          <div className="spacer" />
          <div className="do_not_have_an_account_box">
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </div>
          <div className="spacer" />
          <div className="tocs">
            By clicking on Log in, you agree to our{' '}
            <a href="https://muda.tech/#/terms-and-conditions" target="_blank" className="highlight">
              terms & conditions
            </a>{' '}
            and{' '}
            <a href="https://muda.tech/#/privacy-policy" target="_blank" className="highlight">
              privacy policy
            </a>
          </div>
        </div>
      )}
    </div>
  </>
	);
};

export default Login;
