import { createContext, useContext, useReducer } from "react";

const TransactContext = createContext();
const TransactDispatchContext = createContext();

export const TRANSACT_PAGES = {
  CONFIRM_MARKET_TRADE: "CONFIRM_MARKET_TRADE",
  CONFIRM_OTC_TRADE: "CONFIRM_OTC_TRADE",
  TRANSACT: "TRANSACT",
  TRADE_CONFIRMED: "TRADE_CONFIRMED",
  CONFIGURE_PAYMENT_METHODS: "CONFIGURE_PAYMENT_METHODS",
  ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
  ADD_PAYMENT_METHOD_SUCCESS: "ADD_PAYMENT_METHOD_SUCCESS"
};

export const TransactProvider = ({ children }) => {
  const emptyTransactState = {
    page: TRANSACT_PAGES.TRANSACT,
    addPaymentMethodSuccessNext: "",
    initSellCurrency: "",
    tradeTabOption: "",
    otcTradeState: {},
    modelOption: "",
    marketTradeState: {
      buy: {
        currency: "UGX",
        amount: ""
      },
      sell: {
        currency: "USD",
        amount: ""
      }
    }
  };
  
  const [transactState, transactDispatch] = useReducer(transactReducer, emptyTransactState);
  return (
    <TransactContext.Provider value={transactState}>
      <TransactDispatchContext.Provider value={transactDispatch}>{children}</TransactDispatchContext.Provider>
    </TransactContext.Provider>
  );
};

export const useTransact = () => {
  return useContext(TransactContext);
};

export const useTransactDispatch = () => {
  return useContext(TransactDispatchContext);
};

const transactReducer = (transactState, action) => {
  switch (action.type) {
    case "transact":
      return { ...transactState, page: TRANSACT_PAGES.TRANSACT, initSellCurrency: action?.initSellCurrency, tradeTabOption: action?.tradeTabOption };
    case "confirmMarketTrade":
      return { ...transactState, page: TRANSACT_PAGES.CONFIRM_MARKET_TRADE };
    case "confirmOtcTrade":
      return { ...transactState, page: TRANSACT_PAGES.CONFIRM_OTC_TRADE, otcTradeState: action.otcTradeState };
    case "tradeConfirmed":
      return { ...transactState, page: TRANSACT_PAGES.TRADE_CONFIRMED };
    case "setMarketTradeState":
      return { ...transactState, marketTradeState: action.marketTradeState };
    case "setOtcTradeState":
      return { ...transactState, otcTradeState: action.otcTradeState };
    case "configurePaymentMethods":
      return { ...transactState, otcTradeState: action.otcTradeState, page: TRANSACT_PAGES.CONFIGURE_PAYMENT_METHODS };
    case "goToOtcTrade":
      return { ...transactState, page: TRANSACT_PAGES.TRANSACT };
    case "goToAddPaymentMethod":
      return { ...transactState, page: TRANSACT_PAGES.ADD_PAYMENT_METHOD };
    case "goToConfigurePaymentMethods":
      return { ...transactState, page: TRANSACT_PAGES.CONFIGURE_PAYMENT_METHODS };
    case "goToAddPaymentMethodSuccess":
      return { ...transactState, page: TRANSACT_PAGES.ADD_PAYMENT_METHOD_SUCCESS, addPaymentMethodSuccessNext: action.addPaymentMethodSuccessNext };
    case "goToConfirmOtcTrade":
      return { ...transactState, page: TRANSACT_PAGES.CONFIRM_OTC_TRADE };
    case "modelClose":
      return { ...transactState, page: "", modelOption: action.modelOption};
      case "tabSelectionClear":
        return { ...transactState, tradeTabOption: action?.tradeTabOption};
    case "clearState":
      const emptyChatState = {
        placeOrder: false,
        trade: false
      };
      const resetMarketTradeState =  {
                                        buy: {
                                          currency: "UGX",
                                          amount: ""
                                        },
                                        sell: {
                                          currency: "USD",
                                          amount: ""
                                        }
                                      };
      return { ...transactState, ...emptyChatState,  otcTradeState: {}, initSellCurrency: "", marketTradeState: resetMarketTradeState};
    default: {
      throw Error("[transactReducer] Unknown action: " + action.type);
    }
  }
};
