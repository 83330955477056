import { useEffect } from "react";
import "./swaps.scss";
import { useAuth } from "../../../../providers/AuthProvider";
import { useSwapsReport } from "./providers/swapsReportProvider";
import { useReportFilters } from "../../reportsFilterProvider";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Swaps = () => {
	const { isLoggedIn } = useAuth();
	const { filters } = useReportFilters();
	const { fetchSwapsReport, isFetchingSwapsReport, swapsReport } = useSwapsReport();
	const navigate = useNavigate();

	//Get swaps report
	useEffect(() => {
		(async () => {
			// fetch swaps report if logged in
			if (isLoggedIn) {
				await fetchSwapsReport();
			}
		})();
	}, [isLoggedIn]);
	const parseDate = (dateStr) => {
		if (!dateStr || typeof dateStr !== "string" || dateStr == "Invalid date") return null;

		const [datePart, timePart] = dateStr.split(" - ");
		const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
		const [time, period] = timePart.split(" ");
		const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

		let hours24 = hours;
		if (period === "PM" && hours !== 12) hours24 = hours + 12;
		if (period === "AM" && hours === 12) hours24 = 0;

		return new Date(year, month - 1, day, hours24, minutes);
	};

	const applyFilters = (swaps) => {
		let filteredSwaps = swaps;

		if (filters.searchQuery) {
			filteredSwaps = filteredSwaps.filter(
				(swap) =>
					swap.transactionID.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					swap.pair.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					swap.price.buy.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					swap.price.sell.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					swap.amount.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					swap.total.toLowerCase().includes(filters.searchQuery.toLowerCase())
			);
		}

		if (filters.startDate && filters.endDate) {
			const start = new Date(filters.startDate);
			const end = new Date(filters.endDate);

			if (!isNaN(start) && !isNaN(end)) {
				filteredSwaps = filteredSwaps.filter((swap) => {
					const swapDate = new Date(swap?.createdOn); //parseDate(swap.date);
					return swapDate && swapDate >= start && swapDate <= end;
				});
			}
		}

		// Define filter mode based on buyCurrency and sellCurrency
		const buyCurrencyFilter = filters.buyCurrency ? [filters.buyCurrency] : [];
		const sellCurrencyFilter = filters.sellCurrency ? [filters.sellCurrency] : [];

		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		filteredSwaps = filteredSwaps.filter((swap) => {
			let showSwap = true;
			// const swapBuyCurrency  = swap?.buyCurrency;
			// const swapSellCurrency = swap?.sellCurrency;
			const [swapBuyCurrency, swapSellCurrency] = swap.pair.split(" - ").map((currency) => currency.trim());
			switch (filterMode) {
				case "BUY":
					showSwap = buyCurrencyFilter.includes(swapBuyCurrency);
					break;
				case "SELL":
					showSwap = sellCurrencyFilter.includes(swapSellCurrency);
					break;
				case "BOTH":
					showSwap = buyCurrencyFilter.includes(swapBuyCurrency) && sellCurrencyFilter.includes(swapSellCurrency);
					break;
				case "NONE":
				default:
					showSwap = true;
					break;
			}

			return showSwap;
		});

		return filteredSwaps;
	};

	const filteredSwapsReport = applyFilters(swapsReport);

	const viewTxn = ({ txn }) => {
		const id = txn._rawTxn.id;
		switch (txn._rawTxnType) {
			case "request":
				navigate(`/transactiondetails/request/${id}`);
				break;
			case "otctrade":
				navigate(`/transactiondetails/${id}`);
				break;
		}
	};

	return (
		<div className="swaps_report_body">

			{isFetchingSwapsReport && (
				<div className="swaps_report_loader_container">
					Loading swaps report...
					<Loader2 className="swaps_report_loader" />
				</div>
			)}

			{!isFetchingSwapsReport && (
				<table className="report_table swaps_report_table">
					<thead>
						<tr>
							<th>Transaction ID</th>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Total</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
					 {filteredSwapsReport.map((swap) => (
						<tr key={swap.transactionID} onClick={() => viewTxn({ txn: swap })}>
							<td>
								{swap.transactionID}
								<br />
								<span className="help_text">( {swap._rawTxnType} )</span>
							</td>
							<td>{`${swap?.pair}`}</td>
							<td>
								<div>{swap.price.buy}</div>
								<div>{swap.price.sell}</div>
							</td>
							<td>
								{swap.amount}
								<br />
								{swap.type == "SELLING" && <span className="help_text">( you received this )</span>}
							</td>
							<td>
								{swap.total}
								<br />
								{swap.type == "BUYING" && <span className="help_text">( you received this )</span>}
							</td>
							<td>{swap.date}</td>
						</tr>
					))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Swaps;
