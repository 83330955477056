import { useMutation } from "@tanstack/react-query";
import { useTransact, useTransactDispatch } from "../../../providers/TransactProvider";
import "./confirmmarkettrade.scss";
import api from "../../../../../api";
import { useAuth } from "../../../../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import { useRates } from "../../../../../providers/Rates/RatesProvider";
import Big from "big.js";

const Confirmmarkettrade = () => {
	const { authTokens } = useAuth();
	const token = authTokens.IdToken;

	const { rates, getRate, isLoadingRates } = useRates();
	const { marketTradeState } = useTransact();
	const transactDispatch = useTransactDispatch();
	const placeMarketTrade = useMutation({
		mutationFn: ({ order, token }) => {
			return api.placeMarketTrade({ order, token });
		},
		onError: (error, variables, context) => {
			console.log({ tag: "apiError", error, variables, context });
		},
		onSuccess: () => {
			transactDispatch({ type: "tradeConfirmed" });
		},
	});

	const { buy, sell } = { ...marketTradeState };
	const onPlaceMarketTrade = () => {
		const order = {
			type: "MARKET",
			buyCurrency: `${buy.currency}`,
			sellCurrency: `${sell.currency}`,
			sellAmount: `${sell.amount.toString()}`,
		};

		placeMarketTrade.mutate({ order, token });
	};

	const goBack = () => {
		transactDispatch({ type: "transact", tradeTabOption: "MARKET_TRADE" });
	};
	return (
		<div id="confirmmarkettrade_component">
			<div className="confirmmarkettrade_controls">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" onClick={() => goBack()}>
					<path d="M16 4L8 12L16 20" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="confirmmarkettrade_header">
				<p>PLACE NEW ORDER</p>
			</div>
			<div className="confirmmarkettrade_summary">
				<div className="confirm_summary">
					<div className="confirm_header">Confirmation Page</div>
					<div className="confirm_info">
						<div className="confirm_sell">
							<div className="left_label">Selling</div>
							{sell && (
								<div className="right_label">
									{Big(sell.amount).round(4).toString()} {sell.currency}
								</div>
							)}
						</div>
						<div className="confirm_buy">
							<div className="left_label">Buying</div>
							{buy && (
								<div className="right_label">
									{Big(buy.amount).round(4).toString()} {buy.currency}
								</div>
							)}
						</div>
						<div className="confirm_exchange_rate">
							<div className="left_label">Exchange rate</div>
							<div>
								<div className="right_label">
									1{sell.currency} = {isLoadingRates ? "Loading..." : getRate({ rates, from: sell.currency, to: buy.currency })}
									{buy.currency}
								</div>
								<div className="right_label">
									1{buy.currency} = {isLoadingRates ? "Loading..." : getRate({ rates, from: buy.currency, to: sell.currency })}
									{sell.currency}
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div data-testid="markettradeform-submitmarkettrade" onClick={onPlaceMarketTrade} className="summary">
					<div className="place_order">{placeMarketTrade.isPending ? <Loader2 className="create_group_loader" /> : "Submit"}</div>
				</div>
			</div>
		</div>
	);
};
export default Confirmmarkettrade;
