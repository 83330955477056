import React, { useState, useRef, useEffect } from "react";
import { Loader2, Info }     from "lucide-react";
import PopModel              from "../../../components/models/model";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";
import ChangeAccountPassword from  "./forms/changePasswordForm/changepassword"
import { useAuth }           from "../../../providers/AuthProvider";
import { useMutation } from "@tanstack/react-query";
import {  useNavigate } from "react-router-dom";
import api from "../../../api";
import "./passwordandsecurity.scss";


const PasswordSecurity = () => {
	const { authTokens } = useAuth()
	const [bankAccounts, setBankAccounts] = useState([]); 
	const [phoneNumbers, setPhoneNumbers] = useState([]); 
	const [walletAddress, setWalletAddress] = useState([]);


	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [toggleSettingsSection, setToggleSettingsSection] = useState("");


	
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const { paymentMethods, reloadPaymentMethods,  refreshingPaymentMethods }  = usePaymentMethods();
	const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false); 
	const [disableStep, setDisableStep] = useState(""); 
const [totpCode, setTotpCode] = useState("");
const [totpError, setTotpError] = useState("");
	const navigate = useNavigate();
	
	const mfaEnabled = localStorage.getItem("hasMFAEnabled");
	const disable2faDialogRef = useRef(null);
	

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 
											
	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	  // Function to open Enable 2FA modal
    const enable2FA = () => {
        showModel({ operation_type: "enable_user_account_2fa" });
    };

	const disable = useMutation({
		mutationFn: () => {
		  return  api.disable2fa({
			  token: authTokens.IdToken,
			  accessToken: authTokens.AccessToken,
			});
		},
		onError: (error, variables, context) => {
		  const errors = error.response.data.errors;
	  
		},
		onSuccess: async (data, variables, context) => {
		  localStorage.setItem("hasMFAEnabled", false);
		  navigate("/settings");
		},
	  });

	  const verifyTotp = useMutation({
		  mutationFn: (fields) => {
			return api.verifyTotp({
			  totp: fields.authentication_code,
			  token: authTokens.IdToken,
			  accessToken: authTokens.AccessToken,
			});
		  },
		  onError: (error, variables, context) => {
			setErrorMessage("Invalid TOTP. Please try again.");
			console.log("Error verfiying:", error);
		  },
		  onSuccess: async (data, variables, context) => {
		   confirmDisable();  
  
			
		  },
		});
	  

	const disable2FA = () => {
		if (disable2faDialogRef.current) {
			disable2faDialogRef.current.showModal();
			setDisableStep("confirm");
			setIsDisableDialogOpen(true);

		}
    };
	const proceedToTOTPVerification = () => {
		setDisableStep("totp"); 
	};

	const validateTOTPAndDisable = () => {
		verifyTotp.mutate({ authentication_code: totpCode });
	};

	const cancelDisable = () => {
		setIsDisableDialogOpen(false)
		disable2faDialogRef.current.close();
		setTotpCode("");
        setTotpError("");
    };

	const confirmDisable = () => {
        disable.mutate();
        cancelDisable(); 
    };




	const showModel = (options) => {
	   if(options.operation_type !== ""){
		  // close all drop downs 
	      setSelectedAccountMoreDetails("")
	      setSelectedAccountDetails("")
		  setModelDataContent({ ...options}); 
		  setModelStatus(true);
	   }
	}

	const toggleSettingsModel = (options) => {
		if(options.operation_type !== ""){
		  options.operation_type = (options.operation_type === toggleSettingsSection)? "":options.operation_type	
		  setToggleSettingsSection(options.operation_type)
		} else {
		  setToggleSettingsSection("")	
		}
	}
	
	const modelStatusTrack = (e) => {
        setModelStatus(false);
	}

	
	return (
		<div className="accountsettings_paymentmethods_page">

				 
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} ></PopModel> 
			<div className="paymentmethods_heading">Password</div>
			<div className="paymentmethods_tabs">
        		<div className="payment_methods_tabs_body">		

				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Password management</h2>
					</div>  	
					<div className="payment_methods_tabs_body_card_content column_100 clearfix"></div>
					<div className="payment_methods_tabs_body_card_content column_100">  
					  <p>You can change your password. Use at least 8 characters, 1 number, 1 uppercase, 1 lowercase letter & a special character</p>	 
					</div>
					<div className={(toggleSettingsSection === 'change_account_password')?"payment_methods_tabs_body_card_content column_100":"hidden"}>  
					  <ChangeAccountPassword></ChangeAccountPassword>
					</div>

					<div className="column_70">  
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					  <button className="add_new_button" onClick={() => toggleSettingsModel({operation_type: 'change_account_password'})}>{(toggleSettingsSection === 'change_account_password')? 'Close Section':'Change Password'}</button>
					</div>
                  </div>


				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Two Factor Authentication</h2>
					</div>  	
					<div className="payment_methods_tabs_body_card_content column_100 clearfix"></div>
					<div className="payment_methods_tabs_body_card_content column_70"> 
						{mfaEnabled === "true" ?  <p>Two Factor Authentication is currently Enabled on your account</p>	: <p> Two Factor Authentication is currently Disabled on your account</p> } 
					 
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					 <button className="add_new_button" onClick={mfaEnabled === "true" ? disable2FA : enable2FA}>
                        {mfaEnabled === "true" ? "Disable 2FA" : "Enable 2FA"}
                    </button>
					</div>
                  </div>
				  <dialog className="disable_2fa_container" ref={disable2faDialogRef} style={{ display: isDisableDialogOpen ? "flex" : "none" }}>
    {disableStep === "confirm" ? (
        <>
            <div className="disable_2fa_heading">
                Are you sure you want to disable Two Factor Authentication for this account?
            </div>
            <div className="disable_2fa_controls">
                <button onClick={proceedToTOTPVerification} className="disable_2fa_control disable_2fa_confirm">
                    Yes
                </button>
                <button onClick={cancelDisable} className="disable_2fa_control disable_2fa_cancel">
                    No
                </button>
            </div>
        </>
    ) : (
        <>
            <div className="disable_2fa_heading">Enter Authentication Code</div>
            <input
                type="text"
				className="input totp"
                value={totpCode}
                onChange={(e) => setTotpCode(e.target.value)}
                placeholder="Enter your authentication code"
            />
            {totpError && <p className="error_message">{totpError}</p>}
            <div className="disable_2fa_controls">
                <button onClick={validateTOTPAndDisable} className="disable_2fa_control disable_2fa_confirm">
					{verifyTotp.isPending || disable.isPending ? <Loader2 className="loader" /> : <span>Submit</span>}
                </button>
                <button onClick={cancelDisable} className="disable_2fa_control disable_2fa_cancel">
                    Cancel
                </button>
            </div>
        </>
    )}
</dialog>

				


				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Deactivate account</h2>
					</div>  	
					<div className="payment_methods_tabs_body_card_content column_100 clearfix"></div>
					<div className="payment_methods_tabs_body_card_content column_70">  
					  <p>Withdraw funds and delete your Muda account.</p>	 
					  <p><b>This action cannot be undone.</b></p>	 
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					  <button className="add_new_button" onClick={() => showModel({operation_type: 'delete_user_account'})}>{(toggleSettingsSection === 'change_account_password')? 'Activate Account':'Deactivate Account'}</button>
					</div>
                  </div>


				</div>
			</div>
		</div>

	);
};
export default PasswordSecurity;
