import ReactSelect from "react-select";
import _ from "lodash";
import { useTransactDispatch, useTransact } from "../../providers/TransactProvider";
import "./addpaymentmethod.scss";
import { useEffect, useState } from "react";
import { reactSelectStyle } from "../../../../reactselectstyles/reactselectstyles";
import { CURRENCY_TYPES, useRates } from "../../../../providers/Rates/RatesProvider";
import { usePaymentMethods } from "../../../../providers/PaymentMethodsProvider";
import { countries } from "../../../../common/countries";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { useTransactionDetails, useTransactionDetailsDispatch } from "../../../transactionDetails/providers/transactionDetailsProvider";
import { useMutation } from "@tanstack/react-query";
import api from "../../../../api";
import { useAuth } from "../../../../providers/AuthProvider";
import cn from "classnames";
import { useCreateRequestDispatch } from "../../../transactionDetails/createRequest/providers/createRequestProvider";

const PAYMENT_METHODS_PAYMENT_TYPES = {
	BANK_ACCOUNT: "BANK_ACCOUNT",
	PHONE_NUMBER: "PHONE_NUMBER",
	WALLET_ADDRESS: "WALLET_ADDRESS",
};

const PAYMENT_METHODS_PER_CURRENCY_TYPE = {
	[CURRENCY_TYPES.FIAT]: [PAYMENT_METHODS_PAYMENT_TYPES.BANK_ACCOUNT, PAYMENT_METHODS_PAYMENT_TYPES.PHONE_NUMBER],
	[CURRENCY_TYPES.CRYPTO]: [PAYMENT_METHODS_PAYMENT_TYPES.WALLET_ADDRESS],
};

const AddPaymentMethod = () => {
	const { authTokens, isLoggedIn } = useAuth();
	const { otcTradeState } = useTransact();
	const { order } = useTransactionDetails();
	const token = authTokens.IdToken;
	const createRequestDispatch = useCreateRequestDispatch();
	const { reloadPaymentMethods, fetchPaymentOptions, isFetchingPaymentOptions, paymentOptions, bankNameToSwiftCode } = usePaymentMethods();

	const [addPaymentMethodState, setAddPaymentMethodState] = useState({
		paymentTypeOptions: [
			{
				label: "Bank account",
				value: PAYMENT_METHODS_PAYMENT_TYPES.BANK_ACCOUNT,
			},
			{
				label: "Phonenumber",
				value: PAYMENT_METHODS_PAYMENT_TYPES.PHONE_NUMBER,
			},
			{
				label: "Wallet Address",
				value: PAYMENT_METHODS_PAYMENT_TYPES.WALLET_ADDRESS,
			},
		],
		selectedPaymentType: {
			label: "Bank account",
			value: PAYMENT_METHODS_PAYMENT_TYPES.BANK_ACCOUNT,
		},
		selectedCurrency: {
			label: "",
			value: "",
		},
		countryOptions: [],
		selectedCountry: {
			label: "",
			value: "",
		},
	});

	const buyCurrency = (order.pair || "").split(" - ")[1] || otcTradeState.buy.currency;

	const transactDispatch = useTransactDispatch();
	const { currencies, getCurrencyType } = useRates();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();


	//Initiatl Setup
	useEffect(() => {
		if (!isLoggedIn) return;

		(async () => {

			await fetchPaymentOptions();
			const newAddPaymentMethodState = { ...addPaymentMethodState };
			const buyCurrencyCode = buyCurrency || "UGX";
			const selectedCurrency = _.find(currencies, { code: buyCurrencyCode });

			newAddPaymentMethodState.selectedCurrency = {
				label: selectedCurrency.code,
				value: selectedCurrency.code,
			};

			// build country options
			const countryOptions = countries.map((country) => ({
				label: country.name,
				value: country.code,
			}));

			const selectedCountry = _.find(countryOptions, { value: "UG" });
			newAddPaymentMethodState.countryOptions = countryOptions;
			newAddPaymentMethodState.selectedCountry = selectedCountry;

			// Change payment type options based on currency type
			const currencyType = getCurrencyType(selectedCurrency.code);
			const newPaymentTypeOptions = addPaymentMethodState.paymentTypeOptions.filter((paymentMethod) => {
				const paymentType = paymentMethod.value;
				return PAYMENT_METHODS_PER_CURRENCY_TYPE[currencyType].includes(paymentType);
			});

			newAddPaymentMethodState.paymentTypeOptions = newPaymentTypeOptions;
			newAddPaymentMethodState.selectedPaymentType = newPaymentTypeOptions[0];
			setAddPaymentMethodState(newAddPaymentMethodState);

		})();
	}, [isLoggedIn]);

	const backToConfigurePaymentMethods = () => {
		transactDispatch({
			type: "goToConfigurePaymentMethods",
		});
	};

	const goToSuccessPage = () => {
		transactDispatch({
			type: "goToAddPaymentMethodSuccess",
			addPaymentMethodSuccessNext: "goToConfigurePaymentMethods",
		});
		createRequestDispatch({
			type: "goToAddPaymentMethodSuccess",
			addPaymentMethodSuccessNextRequestPage: "goToConfigurePaymentMethods",
		});
	};

	const paymentTypeChanged = (evt) => {
		const newAddPaymentMethodState = { ...addPaymentMethodState };
		newAddPaymentMethodState.selectedPaymentType = evt;
		setAddPaymentMethodState(newAddPaymentMethodState);
	};

	const inputChanged = ({ inputName, inputValue }) => {
		const newAddPaymentMethodState = { ...addPaymentMethodState };
		newAddPaymentMethodState[inputName] = inputValue;
		setAddPaymentMethodState(newAddPaymentMethodState);
	};

	const addPaymentMethod = useMutation({
		mutationFn: ({ paymentMethod, token }) => {
			switch (paymentMethod.type) {
				case PAYMENT_METHODS_PAYMENT_TYPES.BANK_ACCOUNT:
					return api.addPaymentMethodBankAccount({ paymentMethod, token });
				case PAYMENT_METHODS_PAYMENT_TYPES.PHONE_NUMBER:
					return api.addPaymentMethodPhonenumber({ paymentMethod, token });
				case PAYMENT_METHODS_PAYMENT_TYPES.WALLET_ADDRESS:
					return api.addPaymentMethodWalletAddress({ paymentMethod, token });
			}
		},
		onError: (error, variables, context) => {
			console.log({ tag: "apiError", error, variables, context });
		},
		onSuccess: async (data, variables, context) => {
			console.log({ tag: "apiSuccess", data, variables, context });
			await reloadPaymentMethods();
			goToSuccessPage();
		},
	});

	const onAddPaymentMethod = (_paymentMethod) => {
		const paymentMethod      = { ..._paymentMethod };
		paymentMethod.country    = addPaymentMethodState.selectedCountry.value;
		paymentMethod.currency   = addPaymentMethodState.selectedCurrency.value;
		paymentMethod.type       = addPaymentMethodState.selectedPaymentType.value;
		paymentMethod.bankName   = addPaymentMethodState.selectedBankName;
		paymentMethod.swiftCode  = addPaymentMethodState.selectedSwiftCode;
		addPaymentMethod.mutate({ paymentMethod, token });
	};

	const getBankOptions = () => {
		const selectedCountry = addPaymentMethodState.selectedCountry;
		const countryCode = selectedCountry.value;

		let bankOptions = paymentOptions
			.filter((paymentOption) => {
				return `${paymentOption.country_code}`.toUpperCase() == `${countryCode}`.toUpperCase() && `${paymentOption.payment_type}`.toUpperCase() == "BANK";
			})
			.map((paymentOption) => paymentOption.name);

		// All this does is de-duplicate the names
		bankOptions = new Set(bankOptions);
		bankOptions = Array.from(bankOptions);
		bankOptions = bankOptions.map((name) => ({ value: name, label: name }));

		return bankOptions;
	};

	const bankNameChanged = (evt) => {
		const bankName = evt.value;
		const swiftCode = bankNameToSwiftCode[bankName];
		const newAddPaymentMethodState = { ...addPaymentMethodState };

		newAddPaymentMethodState.selectedBankName = bankName;
		newAddPaymentMethodState.selectedSwiftCode = swiftCode;
		setAddPaymentMethodState(newAddPaymentMethodState);
	};

	return (
		<div id="addpaymentmethod_component">
			<div className="addpaymentmethod_controls">
				<svg
					className="addpaymentmethod_controls_back"
					onClick={backToConfigurePaymentMethods}
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 24 24"
					fill="none">
					<path d="M16 4L8 12L16 20" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>

			<div className="addpaymentmethod_header">
				<p>Add payment method</p>
			</div>

			{isFetchingPaymentOptions && (
				<div className="addpaymentmethod_loader_container">
					Loading payment options...
					<Loader2 className="addpaymentmethod_loader" />
				</div>
			)}

			{!isFetchingPaymentOptions && (
				<>
					<div className="addpaymentmethod_inputs">
						<div className="addpaymentmethod_inputbox">
							<div className="addpaymentmethod_inputbox_label">Currency</div>
							<ReactSelect
								styles={reactSelectStyle}
								value={addPaymentMethodState.selectedCurrency}
								options={[{ value: buyCurrency, label: buyCurrency }]}
								onChange={(evt) => {
									inputChanged({
										inputName: "selectedCurrency",
										inputValue: evt,
									});
								}}></ReactSelect>
						</div>
						<div className="addpaymentmethod_inputbox">
							<div className="addpaymentmethod_inputbox_label">Country</div>
							<ReactSelect
								styles={reactSelectStyle}
								value={addPaymentMethodState.selectedCountry}
								options={addPaymentMethodState.countryOptions}
								onChange={(evt) => {
									inputChanged({
										inputName: "selectedCountry",
										inputValue: evt,
									});
								}}></ReactSelect>
						</div>
						<div className="addpaymentmethod_inputbox">
							<div className="addpaymentmethod_inputbox_label">Payment type</div>
							<div data-testid="otctradeform-selectpaymentmethodtype">
							<ReactSelect
								value={addPaymentMethodState.selectedPaymentType}
								options={addPaymentMethodState.paymentTypeOptions}
								styles={reactSelectStyle}
								onChange={paymentTypeChanged}></ReactSelect>
							</div>
							
						</div>

						{addPaymentMethodState.selectedPaymentType?.value == PAYMENT_METHODS_PAYMENT_TYPES.WALLET_ADDRESS && (
							<>
								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Wallet Address</div>
									<input type="text" {...register("walletAddress")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Label / Nickname</div>
									<input type="text" {...register("walletAddressLabel")} />
								</div>
							</>
						)}

						{addPaymentMethodState.selectedPaymentType?.value == PAYMENT_METHODS_PAYMENT_TYPES.PHONE_NUMBER && (
							<>
								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label" data-testid="otctradeform-inputphonenumber">Phone number</div>
									<input  data-testid="otctradeform-paymentphonenumber" type="text" {...register("phonenumber")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Label / Nickname</div>
									<input data-testid="otctradeform-paymentphonenumberlabel" type="text" {...register("phonenumberLabel")} />
								</div>
							</>
						)}

						{addPaymentMethodState.selectedPaymentType?.value == PAYMENT_METHODS_PAYMENT_TYPES.BANK_ACCOUNT && (
							<>
								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Bank name</div>
									<ReactSelect styles={reactSelectStyle} options={getBankOptions()} onChange={bankNameChanged} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Account holder name</div>
									<input type="text" {...register("accountName")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Bank account number</div>
									<input type="text" {...register("accountNumber")} />
								</div>

								
								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Swift code</div>
									<input type="text" {...register("swiftCode")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">State / Region</div>
									<input type="text" {...register("region")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">City</div>
									<input type="text" {...register("city")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Bank address</div>
									<input type="text" {...register("address")} />
								</div>

								<div className="addpaymentmethod_inputbox">
									<div className="addpaymentmethod_inputbox_label">Beneficiary address</div>
									<input type="text" {...register("beneficiaryAddress")} />
								</div>

								<div className="addpaymentmethod_inputbox hidden">
									<div className="addpaymentmethod_inputbox_label">Building number</div>
									<input type="text" {...register("buildingNumber")} />
								</div>
							</>
						)}
					</div>

					<div className="addpaymentmethod_ctas">
						<div
						data-testid="otctradeform-addpaymentmethod"
							className={cn("addpaymentmethod_cta_add", {
								is_adding_paymentmethod: addPaymentMethod.isPending,
							})}
							onClick={handleSubmit(onAddPaymentMethod)}>
							{addPaymentMethod.isPending && <Loader2 className="addpaymentmethod_loader" />}
							{!addPaymentMethod.isPending && <>Add payment method</>}
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default AddPaymentMethod;
