import React, { useState } from "react";
import "./model.scss";
import ReactSelect from "react-select";
import { useNavigate }   from "react-router-dom";
import AddBankAccount    from "./payment_methods/add_bank_account";
import AddPhoneNumber    from "./payment_methods/add_phone_number";
import AddAccountWallet  from "./payment_methods/add_account_wallet";

import DeleteAccountNumber from "./payment_methods/delete/delete_account_number";
import DeletePhoneNumber   from "./payment_methods/delete/delete_phone_number";
import DeleteWalletAddress from "./payment_methods/delete/delete_wallet_address";

import DeleteUserAccount from "./settings/delete/delete_user_account"
import UserAccount2FA    from "./settings/2fa/user_account_2fa"
import closePopIcon      from "../../assets/settings/close_pop.svg";

const PopModel = ({openModel, openModelFunc, modelContent, modeCloseResponse}) => {
	
    if (!openModel) return null;
	const navigate = useNavigate();
	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleModelClose = (e) => {
	    openModelFunc();
	};

	return (
	  <div className={openModel? "model_page scroll_right":"model_page scroll_right hidden"}>  
		<div className={(modelContent?.operation_type === 'enable_user_account_2fa')? "clearfix group accountsettings_settings_2fa":"clearfix group accountsettings_paymentmethods_model_box"}>

			<div className="accountsettings_paymentmethods_model_close clearfix group">
			  <img src={closePopIcon} alt="" className="close_model_icon" onClick={(e) => handleModelClose()}/>
			</div>	


			{(modelContent?.operation_type === 'add_bank_account')? <AddBankAccount openModelFunc={openModelFunc} modelContent={modelContent} />:''}
			{(modelContent?.operation_type === 'add_phone_number')? <AddPhoneNumber openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
			{(modelContent?.operation_type === 'add_wallet_address')? <AddAccountWallet openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
			{(modelContent?.operation_type === 'delete_bank_account')? <DeleteAccountNumber openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
			{(modelContent?.operation_type === 'delete_phone_number')? <DeletePhoneNumber openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
			{(modelContent?.operation_type === 'delete_wallet_address')? <DeleteWalletAddress openModelFunc={openModelFunc} modelContent={modelContent} />:''}


			{(modelContent?.operation_type === 'delete_user_account')? <DeleteUserAccount openModelFunc={openModelFunc} modelContent={modelContent} />:''}
			{(modelContent?.operation_type === 'enable_user_account_2fa')? <UserAccount2FA openModelFunc={openModelFunc} modelContent={modelContent} />:''}
				
		</div> 
	  </div>
	);
};
export default PopModel;
