import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import cn from "classnames";

import "../settings.scss";
import "./enable2fa.scss";
const UserAccount2FA = () => {
  const { authTokens } = useAuth();
  const [selectedTab, setSelectedTab] = useState("my account");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("");
  const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] =
    useState("");
  const [dataPlaceState, setDataPlaceState] = useState({
    authentication_code: "",
  });
  const [qrImageUrl, setQrImageUrl] = useState(null);
  const [mfaEnabledSuccess, setMfaEnabledSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // toggle payment types tabs
  const handleToggleTabs = async (options) => {
    if (options?.payment_type !== "") {
      setSelectedTab(options?.payment_type);
    }
  };

  const handleToggleAccountDetails = async (options) => {
    if (options?.account_id !== "") {
      if (selectedAccountDetails === options?.account_id) {
        setSelectedAccountDetails("");
      } else {
        setSelectedAccountDetails(options?.account_id);
      }
    }
  };

  const handleToggleAccountMoreDetails = async (options) => {
    if (options?.account_id !== "") {
      if (selectedAccountMoreDetails === options?.account_id) {
        setSelectedAccountMoreDetails("");
      } else {
        setSelectedAccountMoreDetails(options?.account_id);
      }
    }
  };

  const onInputChange = (field, e) => {
    setDataPlaceState((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const process = useMutation({
    mutationFn: (fields) => {
      return api.verifyTotp({
        totp: fields.authentication_code,
        token: authTokens.IdToken,
        accessToken: authTokens.AccessToken,
      });
    },
    onError: (error, variables, context) => {
      setErrorMessage("Invalid TOTP. Please try again.");
      console.log("Error enabling 2FA:", error);
      setMfaEnabledSuccess(false);
      setMfaEnabled(false);
    },
    onSuccess: async (data, variables, context) => {
      setMfaEnabledSuccess(true);
      localStorage.setItem("hasMFAEnabled", true);
      
    },
  });

  // Fetch the QR code image when the component mounts
  useEffect(() => {
    const fetch2faQrCode = async () => {
      try {
        const response = await api.enable2fa({
          token: authTokens.IdToken,
          accessToken: authTokens.AccessToken,
        });
        const qrCodePng = response.data;
        const srcForImage = URL.createObjectURL(qrCodePng);
        setQrImageUrl(srcForImage);
      } catch (error) {
        console.error("Error fetching 2FA QR code:", error);
      }
    };

    fetch2faQrCode();
  }, []);

  const onSaving = async (fields) => {
    setIsLoading(true);
    process.mutate(fields, {
      onSettled: () => setIsLoading(false),
    });
  };

  return (
    <>
      {!mfaEnabledSuccess ? (
        <>
          <div className="accountsettings_paymentmethods_model">
            <div className="accountsettings_paymentmethods_model_heading">
              Two Factor Authentication
            </div>

            <div className="mfa_container">
              <div className="qr ">
                {qrImageUrl ? (
                  <img src={qrImageUrl} alt="QR Code" className="qr_image" />
                ) : (
                  <p>Loading QR code...</p>
                )}
              </div>
              <div className="form_input_field column_70 steps">
                <label className="column_100  text-left margin_bottom_10">
                  {" "}
                  <b>Step 1</b>Open the authenticator mobile app
                </label>
                <label className="column_100  text-left margin_bottom_10">
                  {" "}
                  <b>Step 2</b>Scan the QR code{" "}
                  {/* <strong>hc67 dqap kmtu 6y63</strong> */}
                </label>
                <label className="column_100  text-left">
                  {" "}
                  <b>Step 3</b>Enter the generated code below{" "}
                </label>

                <div className="form_input_field column_100 nopadding text-left">
                  <label className="column_100  text-left nopadding-left margin_top_10">
                    Authentication Code <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="authentication_code"
                    className={cn("column_70 input", {
                      error: errors.authentication_code?.type === "required",
                    })}
                    {...register("authentication_code", { required: true })}
                  />
                  {errors?.authentication_code?.type === "required" && (
                    <span className="input_error">
                      <Info className="input_error_icon" /> Code is required{" "}
                    </span>
                  )}
                  {errorMessage && (
                    <span className="input_error">
                      <Info className="input_error_icon" /> {errorMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="add_continue_button column_100">
              <button onClick={handleSubmit(onSaving)}>
                {isLoading && <Loader2 className="loader" />}
                {!isLoading && <> Continue </>}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="tradeconfirmed_component">
            <div className="tradeconfirmed_container">
              <div className="tradeconfirmed_summary">
                <div className="success_title">Success!</div>
                <div className="success_subtitle">
                  2FA has been enabled for your account
                </div>
                <div className="success_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="55"
                    viewBox="0 0 56 55"
                    fill="none"
                  >
                    <path
                      d="M55.5 27.5C55.5 42.6878 43.1878 55 28 55C12.8122 55 0.5 42.6878 0.5 27.5C0.5 12.3122 12.8122 0 28 0C43.1878 0 55.5 12.3122 55.5 27.5Z"
                      fill="#3E9CF3"
                    />
                    <path
                      d="M47.5769 27.0385C47.5769 37.5531 39.0531 46.0769 28.5385 46.0769C18.0238 46.0769 9.5 37.5531 9.5 27.0385C9.5 16.5238 18.0238 8 28.5385 8C39.0531 8 47.5769 16.5238 47.5769 27.0385Z"
                      fill="#2A64A1"
                    />
                    <path
                      d="M36.5427 22L26.2009 32.3419L21.5 27.641"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UserAccount2FA;
