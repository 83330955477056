import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import { CustomScroll } from "react-custom-scroll";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Select from "react-select";
import { useRates } from "../../../providers/Rates/RatesProvider";
import { countries } from "../../../common/countries";
import api from "../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../providers/AuthProvider";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";
import "./payment_methods.scss";
import successIcon from "../../../assets/successful.svg"; 
import { toast } from 'react-toastify';
import "./payment_methods.scss";

const AddAccountWallet = ({ modelContent, sideModel, openModelFunc }) => {

  const { authTokens } = useAuth();
  const { currencies } = useRates();
  const { fetchPaymentOptions, paymentOptions, reloadPaymentMethods } = usePaymentMethods();
  const [selectedTab, setSelectedTab] = useState("my account");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("");
  const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [successPosted, setSuccessPosted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [dataPlaceState, setDataPlaceState] = useState({
															address:"",
														    currencyOptions: [],
                                                            countries: [],
															accountName:"",
															accountNumber:"",
															region:"",
															city:"",
															beneficiaryAddress:"",
															buildingNumber:"",
															walletAddress:"",
															walletAddressLabel:"",
															country:"UG",
															currency:"",
															type:"WALLET_ADDRESS"
                                                        });

	const defaultCurrency = (modelContent?.data?.currency !== undefined)? modelContent?.data?.currency : "UGX";
	// const { register, handleSubmit, formState: { errors } } = useForm();
	// const process = useMutation({
	// 	mutationFn: (fields) => api.addPaymentMethodPhonenumber({ paymentMethod: fields, token: authTokens.IdToken }),
	// 	onError: (error) => console.error(error.response.data.errors),
	// 	onSuccess: async (data) => {
	// 	if (data.status === 201 || data.status === 200) {
	// 		await reloadPaymentMethods();
	// 		setSuccessPosted(true);
	// 	}
	// 	},
	// });
	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
	  if(options?.payment_type !== ""){
		setSelectedTab(options?.payment_type)
	  }
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){

			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const changeDefaultCountry = async (options) => {

		setSelectedCountry(options);
		const countryOption = 'country';
		setDataPlaceState((prevState) => ({
											 ...prevState,
											 countryOption: options.value,
										 })); 
	}

	const changeDefaultCurrency = async (options) => {
		setSelectedCurrency({value: options.value, label: options.label});
		const currencyOption = 'currency';
		setDataPlaceState((prevState) => ({
											 ...prevState,
											 currencyOption: options.value,
										 })); 																 
	}

    const init = async () => {
			try{
	
				const defaultCurrency = (modelContent?.data?.currency !== undefined)? modelContent?.data?.currency : "UGX";
				// await fetchPaymentOptions();
				const currencyOptions = currencies.filter((currency) => currency.isCrypto === true).map((currency) => ({
														value: currency.code,
														label: currency.code,
													}));

				if(currencyOptions.length > 0){

					setDataPlaceState((prevState) => ({
														...prevState,
														currencyOptions: currencyOptions,
													 }));
					const defaultCurrencyObject =  currencyOptions.filter((option) => option.value === defaultCurrency);
					let selectedCurrencyVal     = defaultCurrencyObject[0];
					setSelectedCurrency(selectedCurrencyVal);
				}

	
				// build country options
				const countryOptions = countries.map((country) => ({
																	label: country?.name,
																	value: country?.code,
																}));
				if(countryOptions.length > 0){
						
					setDataPlaceState((prevState) => ({
															...prevState,
															countries: countryOptions
														 }));
					
					const defaultCountry = countries.filter((country) => country?.currency?.code === defaultCurrency);	 								 
					const DefaultCountry  = countryOptions.filter((option) => option.value === defaultCountry[0]?.code);								
					setSelectedCountry(DefaultCountry[0]);
				}
	
			} catch(e){
				console.log("logging", e)
			}
	}	
	
	
	const initCountry = async () => {
			try{
	
				// build country options
				const countryOptions = countries.map((country) => ({
																			label: country?.name,
																			value: country?.code,
																	}));
				if(countryOptions.length > 0){
						
					setDataPlaceState((prevState) => ({
														...prevState,
														countries: countryOptions
													}));

					const DefaultCountry  = countryOptions.filter((option) => option.value === "UG");								
					setSelectedCountry(DefaultCountry[0]);
				}
	
			} catch(e){
				console.log("logging", e)
			}
	}	

	const editPaymentMethod = () => {
		if(modelContent?.data?.id !== undefined){
			setDataPlaceState({
								... dataPlaceState,
								address:modelContent?.data?.address,
								accountName:modelContent?.data?.accountName,
								accountNumber:modelContent?.data?.accountNumber,
								region:modelContent?.data?.region,
								city:modelContent?.data?.city,
								beneficiaryAddress:modelContent?.data?.beneficiaryAddress,
								buildingNumber:modelContent?.data?.buildingNumber,
								walletAddress:modelContent?.data?.walletAddress,
								walletAddressLabel:modelContent?.data?.walletAddressLabel,
								currency:modelContent?.data?.currency
							});
		}
	}

	useEffect(() => {
		init();
	    initCountry();
		editPaymentMethod();
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const process = useMutation({
		mutationFn: (fields) => {
			return api.addPaymentMethodWalletAddress({ paymentMethod: fields, token: authTokens.IdToken});
		},
		onError: (error, variables, context) => {
            const errors = error.response.data.errors;
		},
		onSuccess: async (data, variables, context) => {

		   //Saving response content
		   const responseData = data.data.data.attributes;
		   if(data.status === 201 || data.status === 200){
			  await reloadPaymentMethods();
			  setSuccessPosted(true);
		   }
		},
	});

	const onSaving = (fields) => {
		fields = {
					...fields,
					accountName:"",
					accountNumber:"",
					region:"",
					city:"",
					address:"",
					beneficiaryAddress:"",
					buildingNumber:"",
					walletAddress:dataPlaceState?.walletAddress,
					walletAddressLabel:dataPlaceState?.walletAddressLabel,
					country:selectedCountry?.value,
					currency:selectedCurrency?.value,
					type:dataPlaceState?.type
				};

		if(fields?.currency === undefined || selectedCurrency?.value === ""){
		   return toast.error("Select currency");
		}	
        process.mutate(fields);
	};
	  
	const onInputChange = (field, e) => {
		setDataPlaceState((prevState) => ({
		  ...prevState,
		  [field]: e.target.value,
		}));
	};


	const handleModelClose = () => {

        if(modelContent?.operation_method_type_step_2 !== undefined && modelContent?.operation_method_type_step_2 !== ""){
		    openModelFunc({operation_type: modelContent?.operation_type_step_2, operation_method_type:modelContent?.operation_method_type_step_2, data: modelContent?.data});
		} else {
			
			openModelFunc();
		}
	};

	return (
		<div className="accountsettings_paymentmethods_model">


		  <div className={successPosted? "success_form": "hidden"}>
			<div className="text_center model_title_section text_center">
				
			    <img src={successIcon} alt="successful"/>
				
				<h3 className="text_center"> Wallet account created </h3>	
				<h4>Your {(selectedCurrency?.value !== undefined )? selectedCurrency?.value: ' '} wallet was added successfully </h4>
				<div className="add_new_button column_100"> 
				  <button onClick={() => handleModelClose()}> Ok </button>
				</div>
			</div>
		  </div>
		  
		  <div className={!successPosted? "": "hidden"}>
			<div className="accountsettings_paymentmethods_model_heading">{(modelContent?.data?.id !== undefined)? 'Edit Wallet Address':'Add Wallet Address'}</div>
			<div className="form_input_field column_100">
					<label>Currency</label>
					<Select
					value={selectedCurrency}
					className="input_select input-x"
					options={dataPlaceState.currencyOptions}
					{...register("currency", { required: false })}
					onChange={changeDefaultCurrency}
					isSearchable
					/>
					{errors.currency?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Currency is required </span>}
			</div>
			<div className="form_input_field column_100">
					<label>Country</label>
					<Select
					value={selectedCountry}
					className="input_select input-x"
					options={dataPlaceState.countries}
					{...register("country", { required: false })}
					onChange={changeDefaultCountry}
					isSearchable
					/>
					{errors.country?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Country is required </span>}
			</div>
			<div className="form_input_field column_100"> 
				<label className="column_100  text-left">Label / Nickname</label>
				<input className="column_100 input" type="text" 
							placeholder="Wallet name"
							name="walletAddressLabel"
							{...register("walletAddressLabel", { required: true })}
							value={dataPlaceState.walletAddressLabel}
							onChange={(e) => onInputChange("walletAddressLabel", e)}  />
				{errors.walletAddressLabel?.type === "required" && (
									  <span className="input_error"><Info className="input_error_icon" /> Wallet address label is required </span>
									)} 				
			</div>
			<div className="form_input_field column_100"> 
				<label className="column_100  text-left">Wallet address</label>
				<input className="column_100 input" type="text" 
							placeholder="Wallet address"
							name="walletAddress"
							{...register("walletAddress", { required: true })}
							value={dataPlaceState.walletAddress}
							onChange={(e) => onInputChange("walletAddress", e)}  />
				{errors.walletAddress?.type === "required" && (
									  <span className="input_error"><Info className="input_error_icon" /> Wallet address is required </span>
									)} 				
			</div>

			<div className="add_new_button column_100"> 
				{process.isPending && <button> <Loader2 className="processing_loading" /> </button>}
				{!process.isPending && <button onClick={handleSubmit(onSaving)}> {(modelContent?.data?.id !== undefined)? 'Edit wallet address':'Add wallet address'} </button>}
			</div>

		  </div>


		</div>
	);
};
export default AddAccountWallet;
