import React from "react";
import { Loader2 } from "lucide-react";
import "./loader.scss";

const Loader = () => {
  return (
    <div id="loading-page">
      <div className="rates_loader_container">
       <div><Loader2 className="rates_loader" />
       <span>Loading ...</span></div>
      </div>
    </div>  
  );
};

export default Loader;
