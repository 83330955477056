import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import { CustomScroll } from "react-custom-scroll";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Select from "react-select";
import Cookies from "js-cookie";

import { useRates } from "../../../../../providers/Rates/RatesProvider";
import { countries } from "../../../../../common/countries";
import api from "../../../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../../../providers/AuthProvider";
import { usePaymentMethods } from "../../../../../providers/PaymentMethodsProvider";
import successIcon from "../../../../../assets/successful.svg"; 
import { toast } from 'react-toastify';
import "./changepassword.scss";

const ChangeAccountPassword = ({ modelContent, sideModel, openModelFunc }) => {

  const { authTokens } = useAuth();
  const { currencies } = useRates();
  const { fetchPaymentOptions, paymentOptions, reloadPaymentMethods } = usePaymentMethods();
  const [selectedTab, setSelectedTab] = useState("my account");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("");
  const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [successPosted, setSuccessPosted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [dataPlaceState, setDataPlaceState] = useState({
	                                                       previousPassword:"",
	                                                       confirmPassword:"",
	                                                       newPassword:"",
	                                                       accessToken:""
														});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const process = useMutation({
		mutationFn: (fields) => {
		  return api.accountChangePassword({ accountDetails: fields, token: authTokens.IdToken});
		},
		onError: (error, variables, context) => {
			const errorMessage = error?.response?.data?.errors?.detail || error?.response?.data?.errors[0]?.detail || 
											"An unexpected request processing error occurred";
			return toast.error(errorMessage);
		},
		onSuccess: async (data, variables, context) => {

		   // finish	
		   const responseData = data.data.data.attributes;
		   if(data.status === 201 || data.status === 200){

			  setSuccessPosted(true);
			  setDataPlaceState({
								  ...dataPlaceState,
								  previousPassword:"",
	                              confirmPassword:"",
	                              newPassword:"",
	                              accessToken:""
							   });
			  toast.success("Password was successfully updated");
			  // check if authResetPassKey was set
			  if(Cookies.get("authResetPassKey")){
				 Cookies.remove("authResetPassKey");
				 location.reload();
			  }
			  
		   }
		},
	});

	const onSaving = (fields) => {
		fields = {
					...fields,
	                previousPassword:dataPlaceState?.previousPassword,
	                newPassword:dataPlaceState?.newPassword,
	                accessToken:authTokens.IdToken,
				};
		if(dataPlaceState?.newPassword === undefined && (dataPlaceState?.newPassword !== dataPlaceState?.confirmPassword)){
			return toast.error("New/confirm passwords don't match");
		}	
        process.mutate(fields);
	};
	  
	const onInputChange = (field, e) => {
		setDataPlaceState((prevState) => ({
		  ...prevState,
		  [field]: e.target.value,
		}));
	};

	const clearForm = () => {
		setDataPlaceState({
							...dataPlaceState,
							previousPassword:"",
							confirmPassword:"",
							newPassword:"",
							accessToken:""
						});
	};
	
	return (
		<div className="accountsettings_paymentmethods_model">
          <div className="reset_password_form">

			<div className="form_input_field column_33.3"> 
				<label className="column_100  text-left">Current Password <sup>*</sup></label>
				<input className="column_100 input" type="password" 
							placeholder="Current password"
							name="previousPassword"
							{...register("previousPassword", { required: true })}
							value={dataPlaceState.previousPassword}
							onChange={(e) => onInputChange("previousPassword", e)}  />
				{errors.previousPassword?.type === "required" && (
					<span className="input_error"><Info className="input_error_icon" /> Field is required </span> )} 				
			</div>

			<div className="form_input_field column_33.3"> 
				<label className="column_100  text-left">New Password <sup>*</sup></label>
				<input className="column_100 input" type="password" 
							placeholder="New password"
							name="newPassword"
							{...register("newPassword", { required: true })}
							value={dataPlaceState.walletAddressLabel}
							onChange={(e) => onInputChange("newPassword", e)}  />
				{errors.newPassword?.type === "required" && (
									  <span className="input_error"><Info className="input_error_icon" /> Field is required </span>
									)} 				
			</div>

			<div className="form_input_field column_33.3"> 
				<label className="column_100  text-left">Confirm Password <sup>*</sup></label>
				<input className="column_100 input" type="password" 
							placeholder="Confirm password"
							name="confirmPassword"
							{...register("confirmPassword", { required: true })}
							value={dataPlaceState.confirmPassword}
							onChange={(e) => onInputChange("confirmPassword", e)}  />
				{errors.confirmPassword?.type === "required" && (
									  <span className="input_error"><Info className="input_error_icon" /> Field is required </span>
									)} 				
			</div>
		  </div>
		  <div>
			<div className="add_new_button column_100 text-right add_new_btn_"> 
				{process.isPending && <button> <Loader2 className="processing_loading" /> </button>}
				{!process.isPending && <button onClick={handleSubmit(onSaving)}> Save</button>}
			</div>
	    	<div className="add_new_button add_new_canel column_50"> 
				<button onClick={() => clearForm()}> Cancel</button>
		    </div>
		  </div>


		</div>
	);
};
export default ChangeAccountPassword;
