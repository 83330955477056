import React from "react";
import { Loader2 } from "lucide-react";
import "./content-loader.scss";

const ContentLoader = () => {
  return (
    <div id="loading-content-page">
      <div className="rates_loader_container">
       <div><Loader2 className="rates_loader" />
       <span>Loading ...</span></div>
      </div>
    </div>  
  );
};

export default ContentLoader;
