import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import PopModel          from "../../../components/models/model";
import moreDetailsIcon   from "../../../assets/settings/more_details.svg";
import editAccountIcon   from "../../../assets/settings/edit_details.svg";
import deleteAccountIcon from "../../../assets/settings/delete_details.svg";
import addedAccountIcon  from "../../../assets/settings/added_success_check.svg";
import showDetailsIcon   from "../../../assets/settings/show_details.svg";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";

import "./accountDetails.scss";

const AccountDetails = () => {

	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const { paymentMethods, reloadPaymentMethods,  refreshingPaymentMethods }  = usePaymentMethods(); 



	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 
											
	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const showModel = (options) => {
	   if(options.operation_type !== ""){
		  // close all drop downs 
	      setSelectedAccountMoreDetails("")
	      setSelectedAccountDetails("")
		  setModelDataContent(options)
		  setModelStatus(true);
	   }
	}
	
	const modelStatusTrack = (e) => {
        setModelStatus(false);
	}

	useEffect(() => {
	},[]);

	return (
		<div className="accountsettings_paymentmethods_page">
		  <PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} ></PopModel> 
		  <div className="paymentmethods_heading">General</div>
		  <div className="paymentmethods_tabs">
        	

		  </div>
		</div>

	);
};
export default AccountDetails;
