import React, { useState, useEffect } from "react";
import { Loader2, Info, Trophy } from "lucide-react";
import "../../model.scss";
import Select from 'react-select';
import { useRates } from "../../../../providers/Rates/RatesProvider";
import CryptoWalletWithdraw from "./content/withdraw/crypto-wallet-withdraw";
import BankWithdraw from "./content/withdraw/bank-withdraw";
import MobileMoneyWithdraw from "./content/withdraw/mobile-money-withdraw";
import { paymentOptions } from "../../../../common/paymentOptions";


const WithdrawAccount = ({openModelFunc, modelContent}) => {

	const { currencies, walletCurrencies } = useRates();
	const [selectedTab, setSelectedTab] = useState("my_account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [withdrawMethodType, setWithdrawMethodType] = useState();
	const [selectedCurrency,  setSelectedCurrency] = useState({});
	const [activeCurrency,  setActiveCurrency] = useState({});
	const [selectedAmount, setSelectedAmount] = useState("");
	const [marketplaceState, setMarketplaceState] = useState({
		currencyOptions: [],
		withdrawalMethods: paymentOptions.filter((option) => option.value !== 'mobile') //[{label: 'To bank account', value: 'bank'}, {label: 'To crypto wallet', value: 'wallet'}] //{label: 'To mobile money', value: 'mobile'},
	});


	useEffect(() => {
		// loading currencies options
		// default is fiat currencies using isCrypto flag
		let withdrawSelectType =  false	
		if(modelContent?.data?.withdrawMethod !== undefined && modelContent?.data?.withdrawMethod?.value === 'wallet'){
		  withdrawSelectType =  true	
		}
		const currencyOptions = walletCurrencies.map((currency) => ({
			value: currency.code,
			label: currency.code,
		}));
        setMarketplaceState((prevState) => ({
												...prevState,
												currencyOptions: currencyOptions,
											}));

		const selecteDefaultCurrency  = modelContent?.data?.currency !== undefined ? modelContent?.data?.currency:"UGX"; 
		let defaultCurrency = currencyOptions.filter((option) => option.value === selecteDefaultCurrency);
        // continuation of withdraw process
		if(modelContent?.data && modelContent?.data?.currency !== undefined){
		   defaultCurrency = currencyOptions.filter((option) => option.value === modelContent?.data?.currency);
		}

		changeDefaultCurreny(defaultCurrency[0]);
		// continuation of deposit amount
		if(modelContent?.data?.amount !== undefined){
			setSelectedAmount(modelContent?.data?.amount);
		}


	}, [])

	const changeDefaultCurreny = async (options) => {
	    
		try{
		  
		  const selectedCurrency = marketplaceState?.currencyOptions?.filter((currency) => currency?.code === options?.value);
		  let defaultProcessingCurrency = walletCurrencies.filter((option) => option.code === options?.value);
		  
		  let selectedWithdrawOption;
		  if(defaultProcessingCurrency[0]?.isCrypto === true){
			
			selectedWithdrawOption  = paymentOptions.filter((option) => option.value === 'wallet');
			selectedWithdrawOption  = selectedWithdrawOption[0];
		  } else {
			
			selectedWithdrawOption  = paymentOptions.filter((option) => option.value === 'bank');
			selectedWithdrawOption  = selectedWithdrawOption[0];
		  } 
		  await changeWithdrawType(selectedWithdrawOption)
		  setSelectedCurrency(options);
		  setActiveCurrency(defaultProcessingCurrency[0]);
		}catch(e){
		  console.log("error", e);
		}
	}

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){

			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const changeWithdrawType = async (options) => {

		setWithdrawMethodType(options)
		await selectCurrencyGroup(options)
	}

	const handleAmountChange = (options) => {
		setSelectedAmount(options?.target?.value);
	}

	const selectCurrencyGroup = (options) => {

		let letOption = false;
		if(options?.value === 'wallet'){
			letOption = true;
		}
		//  loading currencies options
		//  default is fiat currencies using isCrypto flag
		//  .filter((currency) => currency.isCrypto === letOption)

		const currencyOptions = walletCurrencies.map((currency) => ({
			value: currency.code,
			label: currency.code,
		}));

		if(currencyOptions.length > 0){
		  setMarketplaceState((prevState) => ({
			...prevState,
			currencyOptions: currencyOptions,
		  }));
		  setSelectedCurrency(options);
		}
	}


	return (
	  <div className="pop_moddule_pops_model_main">

        <div className="pop_moddule_pops_model">
          <div className="payment_methods_tabs_header">
			<div role="my_account" className={(selectedTab === 'my_account')? "payment_methods_tabs_header_tab_main active_tab_bg":"payment_methods_tabs_header_tab_main"} onClick={() => handleToggleTabs({payment_type: 'my_account'})}>
			  <div className={(selectedTab === 'my_account')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
				<span className="payment_methods_tabs_header_tab_text">My account</span>
			  </div>
			</div>
			{/* <div role="3rd_party_account" className={(selectedTab === '3rd_party_account')? "payment_methods_tabs_header_tab_main drag_left_20 active_tab_bg":"payment_methods_tabs_header_tab_main drag_left_20"} onClick={() => handleToggleTabs({payment_type: '3rd_party_account'})}>
			  <div className={(selectedTab === '3rd_party_account')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
				<span className="payment_methods_tabs_header_tab_text">3rd party account</span>
			  </div>
			</div> */}
	      </div>
	    </div>




		<div className={(selectedTab === 'my_account')? "market_trading pop_model_section pop_model_section_scrolling":"hidden"} >
		    <div className="pop_model_section_blocks">
			 <div className="input_block form_input_field">
				<label>Currency</label>	
				<Select     value={selectedCurrency} 
							className="input_select" 
							onChange={changeDefaultCurreny}
							options={marketplaceState.currencyOptions} 
							isSearchable="true" />
				<input      className="column_100 input"
				            value={selectedAmount} 
					        onChange={(e) => handleAmountChange(e)}  
				            type="text" placeholder="Enter withdraw amount" />  
			 </div>
			</div> 

			<div className={!activeCurrency?.isCrypto ?"pop_model_section_blocks":"hidden"}>
			  <div className="input_block form_input_field">	
			  <label>Withdrawal method </label>	
			  <Select value={withdrawMethodType} 
			          className="input_select" 
			          onChange={changeWithdrawType}
					  options={marketplaceState?.withdrawalMethods?.filter((option) => option.value !== 'wallet')} isSearchable="true" />
			  </div>
			</div> 
		
			{(withdrawMethodType?.value === 'bank')?   <BankWithdraw  withdrawMethod={withdrawMethodType} withdrawCurrency={selectedCurrency?.value} withdrawAmount={selectedAmount} openModelFunc={openModelFunc} modelContent={modelContent}  /> : ''}
			{(withdrawMethodType?.value === 'wallet')? <CryptoWalletWithdraw withdrawMethod={withdrawMethodType} withdrawCurrency={selectedCurrency?.value} withdrawAmount={selectedAmount} openModelFunc={openModelFunc} modelContent={modelContent} /> : ''}
			{(withdrawMethodType?.value === 'mobile')? <MobileMoneyWithdraw withdrawMethod={withdrawMethodType} withdrawCurrency={selectedCurrency?.value} withdrawAmount={selectedAmount} openModelFunc={openModelFunc} modelContent={modelContent} /> : ''}

		</div> 
	  </div>

	);
};
export default WithdrawAccount;
