import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import PopModel          from "../../../components/models/model";
import moreDetailsIcon   from "../../../assets/settings/more_details.svg";
import editAccountIcon   from "../../../assets/settings/edit_details.svg";
import deleteAccountIcon from "../../../assets/settings/delete_details.svg";
import addedAccountIcon  from "../../../assets/settings/added_success_check.svg";
import showDetailsIcon   from "../../../assets/settings/show_details.svg";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";

import "./paymentmethods.scss";

const PaymentMethods = () => {

	const [bankAccounts, setBankAccounts] = useState([]); 
	const [phoneNumbers, setPhoneNumbers] = useState([]); 
	const [walletAddress, setWalletAddress] = useState([]); 
	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const { paymentMethods, reloadPaymentMethods,  refreshingPaymentMethods }  = usePaymentMethods(); 

	const [currentBanksPage, setCurrentBanksPage]   = useState(1);
	const [bankItemsPerPage, setBankItemsPerPage] = useState(12);
	const [totalBanksPages, setBanksTotalPages] = useState("");
	const [startIndex, setStartIndex] = useState("");
	const [currentBankItems, setCurrentBankItems] = useState([]);

	const [currentPhonesPage, setCurrentPhonesPage]   = useState(1);
	const [phoneItemsPerPage, setPhoneItemsPerPage] = useState(3);
	const [totalPhonesPages, setPhonesTotalPages] = useState("");
	const [startPhonesIndex, setStartPhonesIndex] = useState("");
	const [currentPhoneItems, setCurrentPhoneItems] = useState([]);


	const [currentWalletsPage, setCurrentWalletsPage]   = useState(1);
	const [walletItemsPerPage, setWalletItemsPerPage] = useState(10);
	const [totalWalletsPages, setWalletsTotalPages] = useState("");
	const [startWalletsIndex, setStartWalletsIndex] = useState("");
	const [currentWalletItems, setCurrentWalletItems] = useState([]);

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 
											
	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const showModel = (options) => {
	   if(options.operation_type !== ""){
		  // close all drop downs 
	      setSelectedAccountMoreDetails("")
	      setSelectedAccountDetails("")
		  setModelDataContent(options)
		  setModelStatus(true);
	   }
	}
	
	const modelStatusTrack = (e) => {
        setModelStatus(false);
	}

	const fetchPaymentDetails = () => {
		
		const wallets = paymentMethods.filter((w) => w.type === 'WALLET_ADDRESS');
		setWalletAddress(wallets)
		const totalWalletsPages = Math.ceil(wallets.length / walletItemsPerPage);
		setWalletsTotalPages(totalWalletsPages);
		const startWalletsIndex = (currentWalletsPage - 1) * walletItemsPerPage;
		setStartWalletsIndex(startWalletsIndex);
		const currentWalletsItems = wallets.slice(startWalletsIndex, startWalletsIndex + totalWalletsPages);
		setCurrentWalletItems(currentWalletsItems);


	
		const bank    = paymentMethods.filter((w) => w.type === 'BANK_ACCOUNT');
		setBankAccounts(bank);
		const totalBankPages = Math.ceil(bank.length / bankItemsPerPage);
		setBanksTotalPages(totalBankPages);
		const startBanksIndex = (currentBanksPage - 1) * bankItemsPerPage;
		setStartIndex(startBanksIndex);
		const currentBanksItems = bank.slice(startBanksIndex, startBanksIndex + totalBankPages);
		setCurrentBankItems(currentBanksItems);


		const phone   = paymentMethods.filter((w) => w.type === 'PHONE_NUMBER');
		setPhoneNumbers(phone);
		const totalPhonesPages = Math.ceil(phone.length / phoneItemsPerPage);
		setPhonesTotalPages(totalPhonesPages);
		const startPhonesIndex = (currentPhonesPage - 1) * phoneItemsPerPage;
		setStartPhonesIndex(startPhonesIndex);
		const currentPhonesItems = phone.slice(startPhonesIndex, startPhonesIndex + totalPhonesPages);
		setCurrentPhoneItems(currentPhonesItems);
		
	}

	useEffect(() => {
		fetchPaymentDetails();
	},[]);

	useEffect(() => {
	   fetchPaymentDetails();
	},[currentBanksPage]);

	useEffect(() => {
		fetchPaymentDetails();
	},[currentPhonesPage]);

	useEffect(() => {
		fetchPaymentDetails();
	},[currentWalletsPage]);


	// Handle page change
	const handleBanksPageChange = (page) => {
		setCurrentBanksPage(page);
	};

	const handlePhonesPageChange = (page) => {
		setCurrentPhonesPage(page);
	};

	const handleWalletsPageChange = (page) => {
		setCurrentWalletsPage(page);
	};

	return (
		<div className="accountsettings_paymentmethods_page">
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} ></PopModel> 
			<div className="paymentmethods_heading">Payment methods</div>
			<div className="paymentmethods_tabs">
        		<div className="payment_methods_tabs_header">
				  <div role="my_accounts" className={(selectedTab === 'my account')? "payment_methods_tabs_header_tab_main active_tab_bg":"payment_methods_tabs_header_tab_main"} onClick={() => handleToggleTabs({payment_type: 'my account'})}>
					<div className={(selectedTab === 'my account')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
					  <span className="payment_methods_tabs_header_tab_text">My accounts</span>
					</div>
				  </div>
				  <div role="3rd_party_account" className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_header_tab_main drag_left_20 active_tab_bg":"payment_methods_tabs_header_tab_main drag_left_20"} onClick={() => handleToggleTabs({payment_type: '3rd party accounts'})}>
					<div className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
					  <span className="payment_methods_tabs_header_tab_text">3rd party accounts</span>
					</div>
				  </div>
				</div>


				<div className={(selectedTab === 'my account')? "payment_methods_tabs_body": "hidden"}>				  
				  <div className="payment_methods_tabs_body_card">
					<div className="payment_methods_tabs_body_card_content"> 
				      <h2>Bank account</h2>	
					  <div className="listing_account_details_group">

                        {(currentBankItems).map((item, key) => (
							<div className="listing_account_details_group_content flex_container flexing_content" key={key}>
									
									<div className="column_10">
									  <img src={addedAccountIcon} alt="Ziraat Bankası" className={(key === 0 )? "added_bank_account": "hidden"}/>
									</div>

									<div className="column_30">
									<h3> The Bank</h3>
									<p> {item.bankName}</p>
									</div>
									<div className="column_30">
									<h3> Acc Number </h3>
									<p> {item.accountNumber}</p>
									</div>
									<div className="column_20">
									<h3> Name </h3>
									<p> {item.accountName}</p>
									</div>


									<div className="column_10 position_relative">
									<img src={moreDetailsIcon} alt="Ziraat Bankası" className="added_bank_account" 
										onClick={() => handleToggleAccountMoreDetails({account_id: item.id})}/>

									<div className={(selectedAccountMoreDetails === item.id)? "account_details_pop":"account_details_pop hidden"}>
										<article className="clearfix" onClick={() => showModel({operation_type: 'add_bank_account', data: item})}>
										<span>Edit</span>
										<img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
										</article>
										<article className="clearfix" onClick={() => showModel({operation_type: 'delete_bank_account', data: item})}>
										<span>Delete</span>
										<img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
										</article>

									</div>
									</div>
									<div className={(selectedAccountDetails === item.id)? "column_100": "column_100 hidden"}>
									<div className="flex_container flexing_content">
										<div className="column_20">
											<h3> Currency </h3>
											<p> {item.currency} </p>
										</div>
										<div className="column_20">
											<h3> City </h3>
											<p> {item.city} </p>
										</div>
										<div className="column_20">
											<h3> Building Number </h3>
											<p> {item.buildingNumber	} </p>
										</div>
										<div className="column_20">
											<h3> State/Region </h3>
											<p> {item.region} </p>
										</div>

									</div>
									</div>
									<div className="column_100 text-right show_details_section" onClick={() => handleToggleAccountDetails({account_id: item.id})}>
									<span>Show details</span>  
									<img src={showDetailsIcon} alt="show details" className={(selectedAccountDetails === item.id)? "show_details_section_icon twisted_360_2": "show_details_section_icon"} />
									</div>
							</div> 
						))}
					  </div> 
					  <div className="flex gap-2 justify-center text-center pagination-btns">
							{Array.from({ length: totalBanksPages }, (_, i) => (
							<button
								key={i + 1}
								onClick={() => handleBanksPageChange(i + 1)}
								className={`px-3 py-1 border rounded ${currentBanksPage === i + 1 ? 'bg-blue-500 text-white pagination-btn active-pagination-btn' : 'bg-gray-200 pagination-btn'}`}
							>
							  {i + 1}
							</button>
							))}
					  </div>
					</div>  

                    <div className="flex_container flexing_content">
					  <div className="payment_methods_tabs_body_card_content column_70"> 
						<p>Add your bank account, this is where money you receive will be paid to</p>	 
					  </div>
					  <div className="payment_methods_tabs_body_card_btn column_30"> 
						<button className="add_new_button"  onClick={() => showModel({operation_type: 'add_bank_account'})}>Add bank account</button>
					  </div>
					</div> 						
                  </div>

				  <div className="payment_methods_tabs_body_card">
					<div className="payment_methods_tabs_body_card_content"> 
				      <h2>Mobile money number</h2>		

					  <div className="listing_phone_details_group">
					    <h3>Mobile number</h3>
						
                        {(currentPhoneItems).map((item, key) => (
                          <div className="listing_phone_details_group_content flex_container flexing_content"  key={key}> 
							<div className="column_60">
							<p> {item.phonenumber}</p>
							</div>
							<div className="column_10 position_relative">
							<img src={moreDetailsIcon} alt="Ziraat Bankası" className="added_bank_account" onClick={() => handleToggleAccountMoreDetails({account_id: item.id})} />
							<div className={(selectedAccountMoreDetails === item.id)? "account_details_pop":"account_details_pop hidden"}>
								<article className="clearfix" onClick={() => showModel({operation_type: 'add_phone_number', data: item})}>
								<span>Edit</span>
								<img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
								</article>
								<article className="clearfix" onClick={() => showModel({operation_type: 'delete_phone_number', data: item})}>
								<span>Delete</span>
								<img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
								</article>
							</div>
							</div> 	
						 </div>
						))}
					  </div>
					   
					  <div className="flex gap-2 justify-center text-center pagination-btns">
							{Array.from({ length: totalPhonesPages }, (_, i) => (
							<button
								key={i + 1}
								onClick={() => handlePhonesPageChange(i + 1)}
								className={`px-3 py-1 border rounded ${currentPhonesPage === i + 1 ? 'bg-blue-500 text-white pagination-btn active-pagination-btn' : 'bg-gray-200 pagination-btn'}`}
							>
							  {i + 1}
							</button>
							))}
					  </div>
                    </div>
				
                    <div className="flex_container flexing_content">
					  <div className="payment_methods_tabs_body_card_content column_70 clearfix">  
						<p>Add your mobile money number, this is where money you receive will be paid to</p>	 
				   	  </div>
					  <div className="payment_methods_tabs_body_card_btn column_30"> 
						<button className="add_new_button" onClick={() => showModel({operation_type: 'add_phone_number'})}>Add phone number</button>
					  </div>
 					</div>
   				  </div>

				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Address management</h2>

					  {(currentWalletItems).map((item, key) => (
						<div className="listing_phone_details_group full_width"  key={key}>
							<h3><b>{item.currency}</b> - {item.walletAddressLabel}</h3>
							<div className="listing_phone_details_group_content flex_container flexing_content"> 
							<div className="column_60">
								<p> {item.walletAddress}</p>
							</div>
							<div className="column_10 position_relative">

								<img src={moreDetailsIcon} alt="" className="added_bank_account" onClick={() => handleToggleAccountMoreDetails({account_id: item.id})} />
								<div className={(selectedAccountMoreDetails === item.id)? "account_details_pop":"account_details_pop hidden"}>
								<article className="clearfix" onClick={() => showModel({operation_type: 'add_wallet_address', data: item})}>
									<span>Edit</span>
									<img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
								</article>
								<article className="clearfix" onClick={() => showModel({operation_type: 'delete_wallet_address', data: item})}>
									<span>Delete</span>
									<img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
								</article>

								</div>
							</div> 	
							</div>
						</div>
					  ))}
					</div>  	

                    <div className="flex gap-2 justify-center text-center pagination-btns">
					   {Array.from({ length: totalWalletsPages }, (_, i) => (
						  <button
								key={i + 1}
								onClick={() => handleWalletsPageChange(i + 1)}
								className={`px-3 py-1 border rounded ${currentWalletsPage === i + 1 ? 'bg-blue-500 text-white pagination-btn active-pagination-btn' : 'bg-gray-200 pagination-btn'}`}
							>
							  {i + 1}
						</button>
					   ))}
					</div>

					<div className="payment_methods_tabs_body_card_content column_70">  
					  <p>Add your wallet addresses, the addresses will be used for tokens withdraw</p>	 
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					  <button className="add_new_button" onClick={() => showModel({operation_type: 'add_wallet_address'})}>Add wallet address</button>
					</div>
                  </div>

				</div>




				<div className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_body": "hidden"}>


				</div>

			</div>
		</div>

	);
};
export default PaymentMethods;
