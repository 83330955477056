import React, { useState, useEffect  } from "react";
import { Loader2, Info } from "lucide-react";
import "../../../../model.scss";
import ReactSelect from "react-select";
import Select      from 'react-select';
import { toast } from 'react-toastify';
import { formatAmount }  from "../../../../../../common/formatAmount";
import { usePaymentMethods } from "../../../../../../providers/PaymentMethodsProvider";
import { useWallets }          from "../../../../../../providers/WalletsProvider";

const CryptoWalletWithdraw = ({withdrawMethod, withdrawCurrency, withdrawAmount, openModelFunc, modelContent}) => {

	const [selectedTab, setSelectedTab] = useState("market"); 
	const [selectedWalletAddress, setSelectedWalletAddress] = useState({}); 

	const { wallets, getAccountWallet, isLoadingWallets, getUserAccountWallet } = useWallets();
	const { paymentMethods, reloadPaymentMethods, refreshingPaymentMethods }  = usePaymentMethods(); 
	const [selectedAccountWallet, setSelectedAccountWallet] = useState({}); 
	const [marketplaceState, setMarketplaceState] = useState({
																currencyOptions: [{label: 'ETH', value: '1'}, {label: 'USDT', value: '2'}, {label: 'BTC', value: '3'}],
																buyCurrencyFilter: [],
																wallets: wallets,
																sellCurrencyFilter: [],
																cryptoWallets: [],
																amount: withdrawAmount,
																currency: withdrawCurrency,
																withdrawMethod: withdrawMethod,
																accountWallet: [],
																totalBalance: "" ,
																accountWalletFrom: "",
																transactionFee: 0.000,
																currencyNetwork: "MainNet",
																transType:       "WITHDRAW",
																paymentType:     "CRYPTO", 
															});

	// toggle payment types tabs {label: 'mqtWRXtVySmX1en5XSDMumVzdrXc4tqzZR', value: '1'},{label: 'mqtWRXtVySmX1en5XSDMumVzdrXc4tqzZm', value: '2'}
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	}

	// main  init collection 
	const initCollection = async () => {
		try{	

			
			let accountWallet = 'accountWallet';
			let bankAccounts = 'bankAccounts';
			let totalBalance = 'totalBalance';
			let currencyNetwork = 'currencyNetwork';
			let accountWalletFrom = 'accountWalletFrom';
			let walletId = 'walletId';

			let myTotalBalance;
			let myCurrencyNetwork;
			let myAccountWalletFrom;
			let myAccountWallets;
			let myWalletId;
			let selectedAccountWallet;
			let selectedWalletAddress;

			const walletBalance = wallets.filter((wallet) => wallet.currency === withdrawCurrency);
			const walletCollection = paymentMethods.filter((w) => w.currency === withdrawCurrency && w.type === 'WALLET_ADDRESS');
			const defaultWalletOptions  = [{ value: "", label: ""}];

			if(walletBalance[0]?.id !== undefined){
			   myTotalBalance =  walletBalance[0]?.balance;
			   myCurrencyNetwork = walletBalance[0]?.address[0]?.type?.replaceAll('_', ' ');
			   myAccountWalletFrom = walletBalance[0]?.id;
			   myAccountWallets = defaultWalletOptions;
			}

			
			const accountsOptionsDefault = [{ value: "", label: "Select wallet address"}]; 
			const accountsOptionsCollection  = walletCollection.map((wallet) => ({
																					value: wallet.id,
																					label: `${wallet.walletAddressLabel} - ${wallet.walletAddress}` 
																				})); 
			const accountsOptions = accountsOptionsDefault.concat(accountsOptionsCollection);


			if(modelContent?.data?.walletId !== undefined){
                
				const currentWallet  = accountsOptions.filter((wallet) => wallet.value === modelContent?.data?.walletId);
				selectedAccountWallet = currentWallet[0];
				selectedWalletAddress = currentWallet[0];
				myAccountWallets      = accountsOptions;
				myWalletId            = modelContent?.data?.walletId;

			} else {

				if(walletCollection?.length > 0){	
					if(accountsOptions.length > 0){
						selectedAccountWallet = accountsOptions[0];
						selectedWalletAddress = accountsOptions[0]; 
						myAccountWallets = accountsOptions;
						myWalletId = accountsOptions[0].value;
					}
				}  else {
	
					let nodataContent = {
											value: "",
											label: `You have no ${withdrawCurrency} wallet address/s` 
										};
					myAccountWallets = [nodataContent];
					selectedAccountWallet = {};
					selectedWalletAddress = nodataContent; 
					myWalletId = "";
				}
			}
			const field = 'currency';
			const amount = 'amount';
			setMarketplaceState((prevState) => ({
												   ...prevState,
												   [field]: withdrawCurrency, 
												   [amount]: withdrawAmount,
												   [accountWallet]: myAccountWallets,
												   [totalBalance]: myTotalBalance, 
												   [currencyNetwork]: myCurrencyNetwork,
												   [accountWalletFrom]: myAccountWalletFrom,
												   [walletId]: myWalletId
											   }));	
			setSelectedWalletAddress(selectedAccountWallet);
			setSelectedAccountWallet(selectedAccountWallet);
	
		}catch(e){
            console.log("Error section ", e)
		}	
	}

	useEffect(() => {	
		const paymentCollectionLoad = async () => {
			try{	
			    await reloadPaymentMethods();
		    	await refreshingPaymentMethods();
			}catch(e){
				console.log("Error section ", e)
			}	
	    }
		initCollection();
	}, [withdrawCurrency, withdrawAmount, modelContent?.operation_method_type]);
	


	useEffect(() => {	
		initCollection();
	}, []);

	const addAccountWallet = async (e) => {
		openModelFunc({operation_type:'add_wallet_address', operation_type_step_2:'crediting', operation_method_type_step_2:'withdraw', data: marketplaceState});
	}

	const changeDefaultAccountWallet = async (options) => {
		
		setSelectedAccountWallet(options);
		const field = 'walletId';
		setMarketplaceState((prevState) => ({
												...prevState,
												[field]: options.value,
										    }));
	} 

	const confirmAccountWithdraw = async (options) => {
		
		if(marketplaceState.currency === "" || marketplaceState.currency === undefined){
			return toast.error("Provide withdraw currency");
		}
			
		if(marketplaceState.amount === "" || isNaN(parseFloat(marketplaceState.amount)) || parseFloat(marketplaceState.amount) <= 0){
			return toast.error("Provide withdraw amount");
		}
	
		if(parseFloat(marketplaceState.totalBalance) < (parseFloat(marketplaceState.transactionFee) + parseFloat(marketplaceState.amount) )){
			return toast.error(`Wallet has less balance of ${marketplaceState.totalBalance} ${marketplaceState.currency} to withdraw`);
		}

		if(marketplaceState.walletId === undefined || marketplaceState.walletId === ""){
			return toast.error("Select account wallet to send to");
		}
	
		// open model to add bank account
		openModelFunc({data: marketplaceState, operation_type:'confirm_crypto_withdraw',  operation_type_step_2:'crediting', operation_method_type_step_2:'withdraw'});
	} 
	
	
	return (
	  <div className="market_crypto_deposit">
		<div className="market_trading_scroll_ nopadding">
		  <div className="market_trading_scroll_ nopadding">
			<div className="input_block form_input_field padding_top_10">
			    <label> Withdraw to </label>	
				<Select value={selectedAccountWallet} 
						className="input_select" 
						onChange={changeDefaultAccountWallet}
						options={marketplaceState.accountWallet} isSearchable="true" />
				<div className="add_new_paymentmethod_button column_100" onClick={(e) => addAccountWallet(e)}> 
					<button>Add a new wallet address </button> 
				</div>	
			</div>
		  </div>	

          <div className="input_block form_input_field hidden">
			 <label>Select network</label>	
			 <Select value={""} className="input_select" options={marketplaceState.cryptoWalletsNetworks} isSearchable="true" />	  
		  </div>

		</div>	

		<div className="input_block_details padding_top_10">
		   <div>
			  <p>Total balance: <span>{`${formatAmount(marketplaceState?.totalBalance)} ${withdrawCurrency}`}</span></p>	
			</div>
			<div>
			  <p>Amount: <span>{`${formatAmount(marketplaceState?.amount)} ${withdrawCurrency}`}</span></p>	
			</div>
			<div>
			  <p>Trx Fee: <span>{`${formatAmount(marketplaceState?.transactionFee)} ${withdrawCurrency}`}</span></p>	
			</div>
			<div>
			  <p>Network: <span>{`${marketplaceState?.currencyNetwork}`}</span></p>	
			</div>
			<div className="add_new_button column_100" onClick={(e) => confirmAccountWithdraw(e)}> 
				<button> Place order </button>
			</div>
		</div>


	</div>   
	);
};
export default CryptoWalletWithdraw; 
