import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useAuth } from "../AuthProvider";
import api from "../../api";
import Big from "big.js";
import { currencies } from "./currencies";
import { useFeatureFlags } from "../FeatureFlags/FeatureFlagsProvider";

const RatesContext = createContext();
const RatesDispatchContext = createContext();

export const CURRENCY_TYPES = {
	FIAT: "FIAT",
	CRYPTO: "CRYPTO",
};

export const RatesProvider = ({ children }) => {

	const { isFeatureFlagEnabled } = useFeatureFlags();
	const { isLoggedIn } = useAuth();

	const getRates = useQuery({
		queryKey: ["rates"],
		queryFn: () => {
			ratesDispatch({
				type: "fetchingRates",
			});
			return api.getRates();
		},
		enabled: false,
	});

	const getPairPrices = useQuery({
		queryKey: ["pairPrices"],
		queryFn: () => {
			ratesDispatch({
				type: "fetchingCurrencyPairs",
			});
			return api.getCurrencyPairs();
		},
		enabled: false,
	});

	const walletFiatCurrencies = ["USD","KES", "UGX", "CNY"];  //, "TZS"
	const walletCryptoCurrencies = ["BTC", "ETH", "USDT", "CUSD"];

    const fiatCurrencies = ["USD", "UGX", "KES", "NGN", "TZS", "ETB", "GHS", "AED", "GBP", "ZMW", "XAF", "XOF", "CNY"];
    const cryptoCurrencies = ["BTC", "ETH", "USDT", "USDC", "CUSD", "TRX"];

	const supportedCurrencies = [];
	const walletSupportedCurrencies = [];

	//  supported app currencies
	supportedCurrencies.push(...fiatCurrencies);
	if (!isFeatureFlagEnabled("cryptocurrencies")) {
	   supportedCurrencies.push(...cryptoCurrencies);
	}

	// supported wallet currencies
	walletSupportedCurrencies.push(...walletFiatCurrencies);
	if (!isFeatureFlagEnabled("cryptocurrencies")) {
		walletSupportedCurrencies.push(...walletCryptoCurrencies);
	}

	const filteredCurrencies = currencies.filter((currency) => supportedCurrencies.includes(currency.code));
	const filteredWalletCurrencies = currencies.filter((currency) => walletSupportedCurrencies.includes(currency.code));	
    const getRate = ({ rates, from, to }) => {
	  try{
		let rate = "";

		// If `from` currency == rates.base, return the basic exchange rate for the `to` currency
		if (from == rates.base) {
			rate = Big(rates.rates[to]).toString();
			return rate;
		}

		// If `to` currency === fx.base, return the basic inverse rate of the `from` currency
		if (to === rates.base) {
			rate = Big(1).div(Big(parseFloat(rates.rates[from]).toFixed(8))).toString();
			return rate;
		}

		// Otherwise, return the `to` rate multipled by the inverse of the `from` rate to get the
		// relative exchange rate between the two currencies
		// console.log({ rates, to, from });
		rate = Big(rates.rates[to])
			.mul(Big(1).div(Big(parseFloat(rates.rates[from]).toFixed(8))))
			.toString();

		return rate;
		

		}catch(e){
           return 0;
		}
	};

	const convert = ({ rates, from, to, amount }) => {
		const rate = getRate({ rates, from, to });
		const newAmount = Big(amount).mul(Big(rate)).toString();
		return newAmount;
	};

  /*
	Decimal policy : 
	fiat : 2 decimal places
	crypto : 8 decimal places
  */
  const round = ({ amount, currency, isFiat, isCrypto }) => {
    let roundedAmount;
    if (isFiat.has(currency)) {
		try{
			roundedAmount = Big(amount).toFixed(2);
		}catch(err){}
    } else if (isCrypto.has(currency)) {
		try{
			roundedAmount = Big(amount).toFixed(8);
		}catch(err){}
    }

	console.log({roundedAmount})

	if(!!roundedAmount && Big(roundedAmount).gt("0")){
		return roundedAmount
	}else{
		return amount
	}
  };

	const isFiat = new Set();
	const isCrypto = new Set();
	const [ratesState, ratesDispatch] = useReducer(ratesReducer, {
		rates: {},
		pairPrices: {},
		isLoadingRates: false,
		isLoadingCurrencyPairs: false,
		getRate,
		convert,
		currencies: filteredCurrencies,
		walletCurrencies: filteredWalletCurrencies,
		isFiat,
		isCrypto,
		round
	});

	//get rates
	useEffect(() => {
		(async () => {

			const ratesRefetchResult = await getRates.refetch();
			if (ratesRefetchResult.status == "success") {

			    const newRates = ratesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: "fetchedRates",
					rates: newRates,
				});
			}

			const currencyPairsRefetchResult = await getPairPrices.refetch();
			if (currencyPairsRefetchResult.status == "success") {
			   const newCurrencyPairsRates = currencyPairsRefetchResult.data.data.data.attributes;
		       ratesDispatch({
					type: "fetchingCurrencyPairs",
					pairPrices: newCurrencyPairsRates,
				});
			}

		})();
	}, [isLoggedIn]);

	return (
		<RatesContext.Provider value={ratesState}>
			<RatesDispatchContext.Provider value={ratesDispatch}>{children}</RatesDispatchContext.Provider>
		</RatesContext.Provider>
	);
};

export const useRates = () => {
	return useContext(RatesContext);
};

export const useRatesDispatch = () => {
	return useContext(RatesDispatchContext);
};

const ratesReducer = (ratesState, action) => {
	switch (action.type) {
		case "fetchedRates":
			// Convert rates to appropriate format
			const rates = {
				base: "USD",
				rates: {},
			};

			const isFiat = new Set();
			const isCrypto = new Set();

			Object.keys(action.rates.rates.cryptocurrency).forEach((crypto) => {
				isCrypto.add(crypto);
				rates.rates[crypto] = action.rates.rates.cryptocurrency[crypto].rate;
			});

			Object.keys(action.rates.rates.fiat).forEach((fiat) => {
				isFiat.add(fiat);
				rates.rates[fiat] = action.rates.rates.fiat[fiat].rate;
			});

			const getCurrencyType = (code) => {
				let currencyType;

				if (isFiat.has(code)) {
					currencyType = CURRENCY_TYPES.FIAT;
				}

				if (isCrypto.has(code)) {
					currencyType = CURRENCY_TYPES.CRYPTO;
				}

				return currencyType;
			};
			return { ...ratesState, rates, isLoadingRates: false, isFiat, isCrypto, getCurrencyType };
		case "fetchingRates":
			return { ...ratesState, isLoadingRates: true };
		case "fetchingCurrencyPairs":
            const pairPrices = action.pairPrices;
			return { ...ratesState, pairPrices, isLoadingCurrencyPairs: true};
		default: {
			
			throw Error("[ratesReducer] Unknown action: " + action.type);
		}
	}
};
