import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import "../../../../model.scss";
import ReactSelect from "react-select";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { usePaymentMethods } from "../../../../../../providers/PaymentMethodsProvider";
import { useWallets }        from "../../../../../../providers/WalletsProvider";
import { formatAmount }      from "../../../../../../common/formatAmount";

const BankWithdraw = ({withdrawMethod, withdrawCurrency, withdrawAmount, openModelFunc, modelContent}) => {

	const { getWallet, wallets } = useWallets();
	const { paymentMethods, reloadPaymentMethods, refreshingPaymentMethods }  = usePaymentMethods(); 
	const [selectedTab, setSelectedTab] = useState("market"); 
	const [selectedAccountWallet, setSelectedAccountWallet] = useState({}); 
	const [marketplaceState, setMarketplaceState] = useState({
																currencyOptions:    [],
																wallets        :    wallets, 
																buyCurrencyFilter:  [],
																sellCurrencyFilter: [],
																bankAccounts:       [],
																accountWallets:     [],
																withdrawCurrency:   withdrawCurrency, 
																withdrawAmount:     withdrawAmount, 
																requestId:          "1",
																currency:           withdrawCurrency,
																walletId:           "",
																amount:             withdrawAmount,
																transType:          "WITHDRAW",
																paymentType:        "BANK",
																paymentTypeId:      "1",
																paymentReference:   "",
																accountWalletFrom:   "",
																totalBalance  :   0,
																transactionFee:   0,
																withdrawMethod: withdrawMethod
															});
	
	// main  init collection 
	const initCollection = async () => {
		try{	
            
			let accountWallets = 'accountWallets';
			let bankAccounts = 'bankAccounts';
			let totalBalance = 'totalBalance';
			let accountWalletFrom = 'accountWalletFrom';
			let walletId = 'walletId';

			let MySelectedWallet;
			let collectionBankAccounts;
			let MyWalletId;
			let MyAccountWalletFrom;
			let MyAccountTotalBalance;

			const walletBalance = await getWallet({currency: withdrawCurrency});	
			if(walletBalance?.currency !== ""){
			  MyAccountTotalBalance = walletBalance?.balance;
			  MyAccountWalletFrom = walletBalance?.id;	
			}

			const walletCollection = paymentMethods.filter((w) => w.currency === withdrawCurrency && w.type === 'BANK_ACCOUNT');
			const defaultWalletOptions  = [{ value: "", label: ""}];
			MySelectedWallet = {}
			let walletDataContent = { value: "", label: ``};
			const accountsOptionsDefault = [{ value: "", label: "Select payment method"}]; 
			const accountsOptionsCollection  = walletCollection.map((wallet) => ({
																					value: wallet.id,
																					label: `${wallet.bankName} - ${wallet.accountNumber}` 
																				})); 
            
			const accountsOptions = accountsOptionsDefault.concat(accountsOptionsCollection);
			
			if(modelContent?.data?.walletId !== undefined){
				
				let walletDataContent2 = modelContent?.data?.bankAccounts?.filter((option) => option.value === modelContent?.data?.walletId);
				collectionBankAccounts = accountsOptions;
			    MyWalletId = walletDataContent2[0].value;
				MySelectedWallet = walletDataContent2[0];

			} else {
				
				if(walletCollection?.length === 0){
					
					walletDataContent      = { value: "", label: `You have no ${withdrawCurrency} bank accounts`};
					collectionBankAccounts = [walletDataContent];
			        MyWalletId             = walletDataContent.value;
					MySelectedWallet       = walletDataContent;
				} else{
					
					collectionBankAccounts = accountsOptions;
			        MyWalletId = accountsOptions[0].value;
					MySelectedWallet = accountsOptions[0];	
				}

			}

			const field = 'currency';
			const amount = 'amount';

			setMarketplaceState((prevState) => ({
										...prevState,
										[field]: withdrawCurrency,
										[amount]: withdrawAmount,
										[bankAccounts]: collectionBankAccounts,
										[walletId]: MyWalletId,
										[accountWalletFrom]: MyAccountWalletFrom,
										[totalBalance]: MyAccountTotalBalance
									}));
			setSelectedAccountWallet(MySelectedWallet);
		}catch(e){console.log("Error section ", e)}	
	}

	useEffect(() => {
		initCollection();	 
	}, [withdrawCurrency, withdrawAmount, modelContent?.operation_method_type]);

	useEffect(() => {
		
		const paymentCollectionLoad = async () => {
		   await reloadPaymentMethods();
		   await refreshingPaymentMethods();
		}	
		paymentCollectionLoad();
		initCollection();
	}, [])

	const addBankAccount = async (e) => {
	   // open model to add bank account
	   openModelFunc({operation_type:'add_bank_account', operation_type_step_2:'crediting', operation_method_type_step_2:'withdraw', data: marketplaceState});
	}

	const confirmAccountWithdraw = async (options) => {
	
		if(marketplaceState?.accountWalletFrom === undefined || marketplaceState?.accountWalletFrom === ""){
		   return toast.error(`Account has no ${withdrawCurrency} wallet. Contact MUDA for help`);
		}
		if(marketplaceState.currency === ""){
           return toast.error("Provide withdraw currency");
		}
		
		if(marketplaceState.amount === "" || isNaN(parseFloat(marketplaceState.amount)) || parseFloat(marketplaceState.amount) <= 0){
			return toast.error("Provide withdraw amount");
		}

		if(parseFloat(marketplaceState.totalBalance) < (parseFloat(marketplaceState.transactionFee) + parseFloat(marketplaceState.amount) )){
			return toast.error(`Wallet has less balance of ${marketplaceState.totalBalance} ${marketplaceState.currency} to withdraw`);
		}

		if(marketplaceState.walletId === "" && marketplaceState.walletId === undefined){
			return toast.error("Select payment method to send money to");
		}

	    // open model to add bank account
	    openModelFunc({data: marketplaceState, operation_type:'confirm_withdraw',  operation_type_step_2:'crediting', operation_method_type_step_2:'withdraw'});
	} 

	const changeDefaultBankAccount = async (options) => {
		// open model to add bank account
		setSelectedAccountWallet(options);
		const field = 'walletId';
		setMarketplaceState((prevState) => ({
										...prevState,
										[field]: options.value, // Dynamically update the field passed in the argument.
									}));
	} 

	
		


	return (
	  <div className="market_crypto_deposit">
		<div className="market_trading_scroll_ nopadding">
		  <div className="input_block form_input_field padding_top_10">
			<label>Bank</label>	
			<Select value={selectedAccountWallet} 
			        className="input_select" 
					onChange={changeDefaultBankAccount}
					options={marketplaceState.bankAccounts} isSearchable="true" />
			<div className="add_new_paymentmethod_button column_100" onClick={(e) => addBankAccount(e)}> 
				 <button>Add a new bank account </button> 
			</div>		  
		  </div>
		</div>	
		<div className="input_block_details">	
		   <div>
			  <p>Total balance:  <span>{`${formatAmount(marketplaceState?.totalBalance)} ${withdrawCurrency}`}</span></p>	
		   </div>
		   <div>
			  <p>Amount:  <span>{`${formatAmount(marketplaceState?.amount)} ${withdrawCurrency}`}</span></p>	
		   </div>
		   <div>
			   <p>Trx Fee:  <span>{`${formatAmount(marketplaceState?.transactionFee)} ${withdrawCurrency}`}</span></p>
			   <p  className={(marketplaceState?.wallets.length > 0 && (marketplaceState?.accountWalletFrom === undefined || marketplaceState?.accountWalletFrom === ""))? "no-wallet-tag":"hidden"}>{`Account has no ${withdrawCurrency} wallet. Contact MUDA for help`} </p>	
		   </div>

		   <div className="add_new_button column_100" onClick={(e) => confirmAccountWithdraw(e)}> 
			 <button> Place order</button>
		   </div>
		</div>
	</div>  
	);

	
	
};
export default BankWithdraw;
