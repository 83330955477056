import "./rates.scss";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import Header from "../../header/header";
import { useAuth } from "../../providers/AuthProvider";
import { useTransactDispatch } from "../transact/providers/TransactProvider";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
import {dynamicImage} from "../../common/dynamicImage";
import Transact from "../transact/transact";
import ReactSelect from "react-select";
import { useRates } from "../../providers/Rates/RatesProvider";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import _ from "lodash";
import Big from "big.js";

const reactSelectStyleRates = {
	control: (baseStyles, state) => ({
		...baseStyles,
		fontSize: ".9rem",
		fontWeight: "600",
		padding: ".2rem",
		borderRadius: "8px",
		width: "9rem",
		color: "#172c50",
		backgroundColor: "#ffffff",
		borderColor: "#3E9CF3",
	}),
};

const Rates = () => {
	const { isLoggedIn } = useAuth();
	const { pairPrices, isLoadingRates, currencies, rates, getRate } = useRates();
	const transactDispatch = useTransactDispatch();
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const [ratesPairPrices, setRatesPairPrices] = useState(pairPrices?.pairprices);

	
	const [ratesState, setRatesState] = useState({
		baseCurrency: "USD",
		ratesLoaded: false,
	});

	const baseCurrencyChanged = (code) => {
		const newRatesState = _.cloneDeep(ratesState);
		newRatesState.baseCurrency = code;
		setRatesState(newRatesState);
	};




	const findRation = (currency, baseCurrency) => {

		let targetKey = `${currency.toUpperCase()}/${baseCurrency.toUpperCase()}`;

	   if (!pairPrices?.pairprices[targetKey]) {
			const [currency1, currency2] = targetKey.split("/");
			targetKey = `${currency2}/${currency1}`; // Reverse the pair
		}

		const result = pairPrices?.pairprices[targetKey];
		return result;
	}


	const sellRateFormatted = async(baseCurrency, currencyCode) => {
	    
		// const theresponse = await findRation(baseCurrency, currencyCode);
		return `00000 ${currencyCode}`
		
	}

	const buyRateFormatted = async(baseCurrency, currencyCode) => {
		
		// const theresponse = await findRation(baseCurrency, currencyCode);
		return `00000 ${currencyCode}`
	}

	return (
		<>
			{!isLoggedIn && <Header />}
			<div id="rates_page">
				<div className="heading">
					<div className="title">Rates</div>
				</div>

				{(isLoadingRates || _.isEmpty(rates.rates)) && (
					<div className="rates_loader_container">
						Loading rates...
						<Loader2 className="rates_loader" />
					</div>
				)}

				{!isLoadingRates && !_.isEmpty(rates.rates) && (
					<>
						<div className="rates_controls">
							<div className="lhs">
								<div className="default_currency">
									<div className="label">Base currency</div>
									<div className="inputs">
										<ReactSelect
											defaultValue={{
												value: "USD",
												label: "USD",
											}}
											onChange={(evt) => baseCurrencyChanged(evt.value)}
											styles={reactSelectStyleRates}
											options={currencies.filter((c) => c.isCrypto === false).map((c) => ({
												value: c.code,
												label: c.code,
											}))}></ReactSelect>
									</div>
								</div>

								{isLoggedIn && false && (
									<div className="topboar_currencies">
										<div className="label">Pick top bar currencies</div>
										<div className="inputs">
											<ReactSelect styles={reactSelectStyleRates}></ReactSelect>
										</div>
									</div>
								)}
							</div>
							<div className="rhs">
								{isLoggedIn && (
									<div className="transact_controls">
										{/* Transact Buttons */}
										<button
											onClick={() => {
												transactDispatch({ type: "transact" });
											}}
											className="control trade"
											popovertarget="transact_popover"
											popovertargetaction="show">
											<div className="control_icon">
												<img src={dashboard_trade_icon} alt="trade icon" />
											</div>
											<div className="control_title">TRADE</div>
										</button>
										{isFeatureFlagEnabled("deposits") && (
											<button className="control deposit">
												<div className="control_icon">
													<img src={dashboard_deposit_icon} alt="deposit icon" />
												</div>
												<div className="control_title">DEPOSIT</div>
											</button>
										)}
										{isFeatureFlagEnabled("withdrawals") && (
											<button className="control withdraw">
												<div className="control_icon">
													<img src={dashboard_withdraw_icon} alt="withdraw icon" />
												</div>
												<div className="control_title">WITHDRAW</div>
											</button>
										)}
										<div id="transact_popover" popover="auto">
											<Transact />
										</div>
									</div>
								)}
							</div>
						</div>

						<table className="rates_table">
							<thead>
								<tr>
									<th>Currency</th>
									<th>Buy Price</th>
									<th>Sell Price</th>
								</tr>
							</thead>
							<tbody>
								{currencies.map((currency) => {

									const baseCurrency = ratesState.baseCurrency;
									// //console.log({ rates, code: currency.code, baseCurrency });
                                	// let sellRate = getRate({
									// 							rates,
									// 							from: currency.code,
									// 							to: baseCurrency,
									// 					  });
									// const targetKey = `${currency?.code?.toUpperCase()}/${baseCurrency?.toUpperCase()}`;
									// let sellRateFormatted = "";
									// try{
									//   const pairOption    = ratesPairPrices[targetKey];
									// }catch(e){

									//   console.log("error", e)	
									//   sellRate = (currency?.isCrypto)? parseFloat(sellRate) : 1 / parseFloat(sellRate);  // Invert the sell rate  
									//   sellRate = parseFloat(sellRate.toFixed(10));  // Round to 5 decimal places
									//   const locale = Intl.NumberFormat().resolvedOptions().locale;
									//   const options = { minimumFractionDigits: 0, maximumFractionDigits: 10 };
									//   sellRateFormatted = `${sellRate.toLocaleString(locale, options)} ${currency?.code}`;
									//   alert(sellRateFormatted)
									// }


									let buyRateFormat  = "";
									let sellRateFormat = "";
									let targetKey = `${baseCurrency.toUpperCase()}/${currency?.code.toUpperCase()}`;
									if (pairPrices?.pairprices[targetKey]){

										const result = pairPrices?.pairprices[targetKey];
										let referencePrice = + result?.referencePrice;
										sellRateFormat = referencePrice - (referencePrice *(result?.markdown/100));
										buyRateFormat = referencePrice +(referencePrice *(result?.markup/100));

										sellRateFormat = (currency?.isCrypto)? sellRateFormat.toFixed(8) : buyRateFormat.toFixed(4)
										buyRateFormat =  (currency?.isCrypto)? buyRateFormat.toFixed(8) : buyRateFormat.toFixed(4)
										if(currency?.code.toUpperCase() === baseCurrency.toUpperCase()){
										   sellRateFormat = 1;
										   buyRateFormat = 1;
										} 

									} else if(currency?.code.toUpperCase() === baseCurrency.toUpperCase()){
                                        sellRateFormat = 1;
										buyRateFormat = 1; 
									}


									return (
										<tr>
											{/* <td>{currency.code}</td> */}
											<td>
											   <div class="currency_info">
												 <div class="icon">
													<img  src={dynamicImage({imageName: currency.code})} alt={currency.code} />
												 </div>
												 <div class="label">
												  <div class="symbol">{currency.code}</div>
												  <div class="name">{currency.name}</div>
												 </div>
											   </div>
											</td>
											<td><span className="help_text_2">1 {baseCurrency}</span> = {`${buyRateFormat} ${currency.code}`}</td>
											<td><span className="help_text_2">1 {baseCurrency}</span> = {`${sellRateFormat} ${currency.code}`}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				)}
			</div>
		</>
	);
};
export default Rates;
