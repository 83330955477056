import React, { useState, useEffect } from "react";
import { useWallets }   from "../../providers/WalletsProvider";
import { countries }    from "../../common/countries";

const OrderMudaPaymentMethods = ({currency}) => {	
	const { getMudaWallet, mudaWallets } = useWallets();
	const [mudaPaymentMethods, setMudaPaymentMethods] = useState([]);
	const [country, setCountry] = useState("");
	useEffect(() => {	
		fetchMudaWallets(currency);
    }, []);

	const fetchMudaWallets = async (currencyCode) => {
	   const defaultCountry = countries.filter((option) => option?.currency?.code === currency);
	   setCountry(defaultCountry[0]?.code)   
	   const PayMethods =  await getMudaWallet({ country: defaultCountry[0]?.code, currency: currencyCode, paymode: "bank"});
	   const uniqueMudaWalletsData = [...new Map(PayMethods.map(bank => [bank.bankName, bank])).values()];
	   setMudaPaymentMethods(uniqueMudaWalletsData);
	}

	return (
	  <div>   
		{
			mudaPaymentMethods?.filter((payMethod) => payMethod?.currency === currency).map((method, index) => (
				<li key={index}>
					{method?.type === 'PHONE_NUMBER' && 'Mobile Money'}
					 {method?.type === 'BANK_ACCOUNT' && method?.bankName}
					 {method?.type !== 'PHONE_NUMBER' && method?.type !== 'BANK_ACCOUNT' && 'Account Wallet'}
				</li>
			))
		}
		    
	  </div> 
	);
};
export default OrderMudaPaymentMethods;
