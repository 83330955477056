import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import Dashboard from "./dashboard/dashboard";
import Dealrooms from "./dealrooms/dealrooms";
import Kyc from "./kyc/kyc";
import Marketplace from "./marketplace/marketplace";
import MyActivity from "./myactivity/myactivity";
import Reports from "./reports/reports";
import Wallets from "./wallets/wallets";
import Settings from "./settings/settings";
import CreateCompany from "./create_company_account/createCompany";
import VerifyCompanyAccount from "./verify_company_account/verifyCompanyAccount";
import TransactionDetails from "./transactionDetails/transactionDetails";
import RequestTransactionDetails from "./transactionDetails/requestTransactionDetails/requestTransactionDetails";
import Rates from "./rates/rates";

const checkAutoPasssword = () => {
	return Cookies.get("authResetPassKey");
}

const workspaceRoutes = [
  { index: true, element: (checkAutoPasssword() === 'true') ? <Settings />:<Dashboard /> },
  { path: "kyc", element: (checkAutoPasssword() === 'true') ? <Settings />:<Kyc /> },
  { path: "wallets", element: (checkAutoPasssword() === 'true') ? <Settings />:<Wallets />},
  { path: "myactivity", element: (checkAutoPasssword() === 'true') ? <Settings />:<MyActivity />},
  { path: "marketplace", element: (checkAutoPasssword() === 'true') ? <Settings />:<Marketplace />},
  { path: "transactiondetails", element: (checkAutoPasssword() === 'true') ? <Settings />:<TransactionDetails />},
  { path: "transactiondetails/:orderId", element:  (checkAutoPasssword() === 'true') ? <Settings />:<TransactionDetails />},
  { path: "transactiondetails/request/:requestId", element: (checkAutoPasssword() === 'true') ? <Settings />:<RequestTransactionDetails /> },
  { path: "dealrooms", element: (checkAutoPasssword() === 'true') ? <Settings />:<Dealrooms />},
  { path: "reports", element:  (checkAutoPasssword() === 'true') ? <Settings />:<Reports />},
  { path: "settings", element: <Settings />},
  { path: "create_company", element:  (checkAutoPasssword() === 'true') ? <Settings />:<CreateCompany />},
  { path: "verify_company_account", element:  (checkAutoPasssword() === 'true') ? <Settings />:<VerifyCompanyAccount />},
  { path: "rates", element: <Rates /> },
];

export default workspaceRoutes;
